import { useEffect, useState } from "react"
import { iAxios } from "../../../lib/utils"
import { useParams } from "react-router"
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { actionFetchAdminUsers, fetchUserInfo } from "../../../lib/actions";
import classNames from "classnames";
import { GenericForm } from "../../common/GenericForm";

const { Spinner, ModalManager } = require("../../common/Helper")


const UserFromFields = [
    {
        name: 'id',
        required: true,
        type: 'hidden',
    }, {
        name: 'name',
        required: true,
        placeholder: 'Name',
        label: 'Name',
    }, {
        name: 'email',
        required: true,
        label: 'Email Id',
        placeholder: 'Email id'
    }, {
        label: 'Role',
        name: 'role',
        className: 'w3-select w3-border w3-margin-bottom',
        element: 'select',
        options: [{
            value: 'Admin',
            label: 'Admin'
        }, {
            value: 'Team Member',
            label: 'Team member'
        }]
    }, {
        name: 'teamLead',
        type: 'email',
        className: 'w3-select',
        label: 'Team Lead Email id',
        element: 'select'
    }, {
        label: 'Start Date',
        name: 'startDate',
        type: 'date',
    }, {
        label: 'End Date',
        name: 'endDate',
        type: 'date',
    }, {
        label: 'Team',
        name: 'team',
        placeholder: 'Enter Team Name'
    }, {
        label: 'Department',
        name: 'department',
        placeholder: 'Enter Department name'
    }, {
        label: 'Designation',
        name: 'designation',
        placeholder: 'Senior Engineer'
    }, 
    {
        label: 'Auto Checkout Config',
        name: 'autoCheckoutSec',
        className: 'w3-select w3-border w3-margin-bottom',
        element: 'select',
        options: [{
            value: '-1',
            label: 'Disable App'
        }, {
            value: '1800',
            label: '30 mins'
        },{
            value: '3600',
            label: '1 Hour'
        },{
            value: '7200',
            label: '2 Hours'
        }]
    },
    {
        label: 'Status',
        name: 'status',
        className: 'w3-select w3-border w3-margin-bottom',
        element: 'select',
        options: [{
            value: 'Active',
            label: 'Active'
        }, {
            value: 'Inactive',
            label: 'Inactive'
        },{
            value: 'Blocked',
            label: 'Blocked'
        }]
    }, {
        label: 'Handles (Comman Separated)', 
        name: 'handles',
        placeholder: 'Abcd EFG, ijslkn'
    }]

const UserModal = ({ listData }) => {

    const {formData,userInfo} = useSelector(st => ({
        formData: st.modalStatus['userModal'],
        userInfo: st.userInfo
    }));
    
    const [formConfig, setFormConfig] = useState(UserFromFields);
    const dispatch = useDispatch();
    const handleSubmit = (data) => {
        iAxios.post('/api/v1/admin/manage-user',data).then(resp=>{
            if(resp.data.success){
                dispatch(actionFetchAdminUsers());
                dispatch({
                    type: 'hide-modal',
                    name: 'userModal'
                });
                if(data.email === userInfo.email) {
                    dispatch(fetchUserInfo());
                }
            } else {

            }
            
        })
    }

    useEffect(() => {
        if (!formData || !listData) {
            return;
        }

        const excludeList = ['team','handles','autoCheckoutSec']
        const nConf = formConfig.map(element => {
            let ret = {}
            if(element.type === 'hidden'){
                console.log('hidden',element)
                return Object.assign(ret, element);
            }
            if (formData.extUrl?.indexOf("zoho://") >= 0 && !excludeList.includes(element.name)) {
                ret = Object.assign(ret, { disabled: true })
            }
            if (element.name === 'teamLead' && listData) {
                const options = listData
                    .filter(item => item.status === 'Active' && item.email !== formData.email)
                    .map(item => {
                        return {
                            label: item.name,
                            value: item.email
                        }
                    })
                options.push({ label: '', value: '' })
                options.sort((a, b) => a.label.localeCompare(b.label));
                element.options = options;
            }
            return Object.assign(ret, element);
        });
        setFormConfig(nConf);
    }, [listData, formData]);

    return <ModalManager modalName="userModal">
        <div className="w3-row-padding">
            <div className="w3-col m12">
                <h3>Manage User</h3>
                {formData?.extUrl?.indexOf("zoho://") >= 0 && <div 
                    className="w3-green w3-round w3-padding w3-margin-bottom">Data Synced from Zoho, Only selected fields can not be edited</div>}
            </div>
        </div>
        {formData && <GenericForm fields={formConfig}
            wrapClass='w3-col m6 l6'
            onSubmit={handleSubmit}
            initVals={formData}
            formClass='w3-row-padding'
            submitOptions={{ className: 'w3-button w3-round w3-border w3-padding-small w3-theme-d2 w3-right' }}
            fieldClass='w3-input w3-border w3-margin-bottom w3-round' />
        }
    </ModalManager>
}

export const Users = () => {
    
    const [users, setUsers] = useState([]);
    const [statusList,setStatusList] = useState([]);
    const [statusFilter, setStatus] = useState('Active');
    const dispatch = useDispatch();

    const data = useSelector(st => st?.apiData?.adminUsers);
 
    const showModal = () => {
        dispatch({
            type: 'show-modal',
            name: 'userModal',
            data: {}
        })
    }
    useEffect(() => {
        dispatch(actionFetchAdminUsers())
    }, [1])

    useEffect(()=>{
        if (!data){
            return;
        }
        const sl = data.reduce((acc,item)=> {
            if (!acc.includes(item.status)){
                acc.push(item.status)
            }
            return acc;
        },[])
        console.log(sl);
        setStatusList(sl.sort())
    },[data])
    useEffect(() => {
        if (!data) {
            return;
        }
        setUsers(data.filter(item => item.status === statusFilter));
    }, [data, statusFilter])
    const editUser = (user) => {
        dispatch({
            type: 'show-modal',
            name: 'userModal',
            data: Object.assign(user, {
                startDate: moment(user.startDate).toDate(),
                endDate: moment(user.endDate).toDate(),
            })
        })
    }
    
    return <div className="">
        <div className="w3-row w3-padding-16">
            <div className="w3-left">
                <div className="w3-bar">
                    <select className="w3-select w3-border w3-round w3-padding" onChange={e => setStatus(e.target.value)} value={statusFilter}>
                        {statusList.map(item=> <option value={item}>{item}</option>)}
                    </select>
                </div>
            </div>
            <div className="w3-right">
                <button className="w3-button w3-theme-d1 w3-round" onClick={showModal}>Create User</button>
            </div>
        </div>
        <table className="w3-table w3-table-all">
            <thead>
                <tr>
                    <td>#</td>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Role</td>
                    <td>Team</td>
                    <td>Designation</td>
                    <td>Start Date</td>
                    {statusFilter === 'Active' && <td>Reporting To</td>}
                    {statusFilter !== 'Active' && <td>End Date</td>}
                    <td style={{ width: '60px' }}></td>
                </tr>
            </thead>
            <tbody>
                {data == null && <tr>
                    <td colSpan={8}>
                        <Spinner />
                    </td>
                </tr>}
                {(data && users && users.length === 0) && <tr>
                    <td colSpan={8}>
                        List is empty
                    </td>
                </tr>}
                {(data && users) && users.map((item, idx) => <tr className="w3-display-container" key={item.email}>
                    <td>{idx + 1}</td>
                    <td >{item.name}</td>
                    <td title={`handles  : ${item.handles}`}>{item.email}
                        <span className="w3-badge w3-right w3-black">{item.handles && item.handles?.split(',')?.length}</span>
                    </td>
                    <td>{item.role}</td>
                    <td>{item.team}</td>
                    <td>{item.designation}</td>
                    <td>{moment(item.startDate).format('DD MMM yyyy')}</td>
                    {statusFilter !== 'Active' && <td>{item.endDate && moment(item.endDate).format('DD MMM YYYY')}</td>}
                    {statusFilter === 'Active' && <td>{item.teamLead}</td>}
                    <td >
                        <button style={{ padding: '0px 4px  ' }} onClick={e => editUser(item)}
                            className="w3-button w3-display-hover w3-round w3-text-blue w3-hover-text-black ">Edit</button>
                    </td>
                </tr>)}

            </tbody>
        </table>
        <UserModal listData={data} />
    </div>
}