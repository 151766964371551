import React, { Component, useEffect, useState } from 'react'
import { Link, useParams, withRouter } from 'react-router-dom';
import { Spinner } from '../common/Helper';
import { formatSecs, paginatedFetch, fetchData, iAxios } from '../../lib/utils'
import ReactMarkdown from 'react-markdown'; 

const Poker = () => {

    const [issueList, setIssueList] = useState(null);
    const [plan, setPlan] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const { product } = useParams();

    const loadData = (force = 0) => {
        return fetchData(`/api/v1/plan/poker-play`, {
            force: force,
            project: product,
        }).then(resp => {
            setPlan(resp.data.data);
            return resp.data;
        }).catch(ex => {
            console.error("Error XXX:", ex);
            alert(ex.message);
            document.location.reload();
        });
    }

    const fetchIssues = (mode = 'normal') => {
        // this.refs.reload_btn.disabled = true;
        setIssueList(null);
        setPlan(null)
        setErrorMessage(null);
        paginatedFetch('/api/v1/tickets/list', {
            project: product,
            mode: mode,
            // state:'open',
            milestone: 'all',
            // state:'open'
        }).then(res => {
            setIssueList(res.data.sort((a, b) => a.weight - b.weight));
            loadData();
            // this.refs.reload_btn.disabled = false;
        }).catch(ex => {
            console.error('Error : Poker: 51 ', ex);
            alert(ex.message);
        })
    }

    useEffect(() => {
        loadData().then(data => {
            fetchIssues();
        })
        const timeInterval = setInterval(() => {
            loadData();
        }, 5000);
        return () => {
            if (timeInterval) {
                clearInterval(timeInterval);
            }
        }
    }, [])


    const setError = (result) => {
        if (result.status) {
            return;
        }
        setErrorMessage(result.message);
        setTimeout(() => {
            setErrorMessage(null)
        }, 5000)
    }

    const playPoker = (value) => {
        iAxios.post('/api/v1/plan/poker-issue', {
            value: value,
            project: product,
        }).then(resp => {
            setError(resp.data);
            setPlan(resp.data.data);
        });
    }

    const handleIssue = (issue) => {
        iAxios.post('/api/v1/plan/poker-issue', {
            issue: issue,
            project: product,
        })
            .then(resp => {
                const result = resp.data;
                setError(result);
                setPlan(resp.data.data);
            }).catch(ex => {

            })
    }
    const renderIssue = () => {
        if (!plan) {
            return null;
        }
        const vals = [1, 2, 3, 5, 8, 13, 21];

        return (<div className="w3-padding">
            {errorMessage ? <div className="w3-red w3-padding">{errorMessage}</div> : null}
            <table className="w3-table text-align-left w3-table-all">
                <thead>
                    <tr>
                        <td>Player</td>
                        <td>Estimate</td>
                    </tr>
                </thead>
                <tbody>
                    {plan.poker && Object.keys(plan.poker).map(email =>
                        <tr key={email}>
                            <td>{email}</td>
                            <td>{plan.poker[email]}</td>
                        </tr>)}
                </tbody>
            </table>
            <div className="w3-margin-top" >
                {plan.poker ? <button onClick={e => loadData(1)} className="w3-button w3-teal w3-block">Refresh</button> : null}
                {!plan.poker && plan.issue && vals.map(item => {
                    return <button key={item} value={item}
                        onClick={e => playPoker(item)} 
                        className="w3-button w3-center w3-green  " style={{
                            width: "12%",
                            margin:"8px",
                            padding: "16px 8px",
                            textAlign: "center",
                            marginLeft: "3px"
                        }} >
                        {item}
                    </button>
                })
                }
            </div>
            {plan.issue ?
                <div><a href={plan.issue.url} target="_blank"> 
                <h4>{plan.issue && plan.issue.title}</h4></a>
                    <div className="cgs-markdown">{plan.issue.description}</div>
                </div> : <h4> Select An issue </h4>
            }
        </div>)

    }

    const refreshButton = (<div className="w3-padding w3-right">
        <button onClick={e => fetchIssues('refresh')}
            // ref="reload_btn"
            className="w3-right w3-small w3-round w3-blue w3-button">
            <i className="ion-refresh"></i>
        </button>
    </div>);

    return <div > 
        <div className="w3-responsive">
            {issueList ? null : <Spinner />}
            <div className="w3-row">
                <div className="w3-col m6 w3-center">
                    {renderIssue()}
                </div>
                <div className="w3-col m6 w3-padding">
                    <table className="w3-table w3-table-all">
                        <tbody>
                            {issueList && issueList
                                .map((item, idx) => {
                                    return <tr key={idx} style={{ cursor: 'pointer' }} >
                                        <td>{idx + 1}</td>
                                        <td>
                                            <span onClick={e => handleIssue(item)}>
                                                {item.title}
                                                <a target="_blank" className="w3-right" href={`${item.url}`}>View</a>
                                                {item.weight && <span className="cgs-small-tag w3-blue weight w3-right">{item.weight}</span>}
                                                {item.labels.map(lb => <span key={lb} className="cgs-small-tag w3-green w3-right"> {lb}</span>)}
                                                {item.timeEstimate > 0 && <span className="cgs-small-tag w3-blue-grey w3-right">{formatSecs(item.timeEstimate)}</span>}
                                            </span>
                                        </td>
                                    </tr>
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

}

export default Poker;