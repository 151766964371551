import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { iAxios } from '../../lib/utils';
import { Spinner } from '../common/Helper';
import { LinearMonth } from '../common/Calendar';

const fRenderContrib = ({ commits, email }) => {
    const rCommits = commits
        .filter(item => {
            if (item.title.indexOf('Merge') >= 0) {
                return false
            }
            return item.committer_email.indexOf(email) >= 0;
        }).sort((a, b) => b.committed_date.localeCompare(a.committed_date));

    const nMonths = rCommits.reduce((agg, item, idx) => {
        const date = moment(item.committed_date);
        const monYr = date.startOf('mon');
        const mon = monYr.format('YYYY-MM-01')
        if (!agg[mon]) {
            agg[mon] = []
        }
        agg[mon].push({
            id: `comm_${idx}_${item.short_id}`,
            date: date.format('YYYY-MM-DD'),
            title: `${item.committer_email} - ${item.title}`
        });
        return agg;
    }, {});
    return <div>
        <div className="w3-padding" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <LinearMonth months={nMonths} />
        </div>
        {
            rCommits.length > 0 && <div className="w3-padding"><table className="w3-table-all w3-margin-top">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Email</th>
                        <th>project</th>
                        <th>Message</th>
                        <th>Contrib</th>
                    </tr>
                </thead>
                <tbody>
                    {rCommits.map((item, idx) => {
                        return <tr>
                            <td>{idx + 1}</td>
                            <td style={{ textAlign: 'right' }}>{moment(item.committed_date).format('YYYY-MM-DD')}</td>
                            <td>{item.committer_email}</td>
                            <td>{item.web_url.replace(/-.*/, '').replace(/.*gitlab.com\//, '')}</td>
                            <td><a href={item.web_url} target="_blank">{item.title}</a></td>
                            <td>+{item.stats?.additions}, -{item.stats?.deletions} , total: {item.stats?.total}</td>
                        </tr>
                    })}</tbody>
            </table>
            </div>
        }
    </div>
}

const RenderContrib = React.memo(fRenderContrib);

export const ProjectContrib = (props) => {

    const [projects, setProjects] = useState("");
    const [email, setEmail] = useState(props.email || "");
    const [loading, setLoading] = useState(false);
    const [commits, setCommits] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [selectedList, setSelected] = useState([]);
    const [fetchedList, setFetchedList] = useState([]);
    const [showDropdown, setShowDropdown] = useState('');
    const [fetchedBranch, setFetchedBranch] = useState('');
    
    const [branch, setBranch] = useState('');

    const dbSetEmail = debounce(setEmail, 700, {
        maxWait: 700,
        leading: false,
        trailing: true
    });

    const dbSetBranch = debounce(setBranch, 700, {
        maxWait: 700,
        leading: false,
        trailing: true
    });


    useEffect(()=>{

    },[branch])

    const fetchCommits = () => {
        const projectIdList = projectList.filter(item => selectedList.some(s => s === item.fullPath))
            .map(item => item.id);
        let accumulated = [];
        let fetched = [];
        const FMT = 'YYYY-MM-DDTHH:mm:ss';


        const fetchData = (projectId, until, resolve, reject) => {
            if (!projectId) {
                return resolve(accumulated);
            }
            const id = parseInt(projectId.replace('gid://gitlab/Project/', ''));
            iAxios.get(`/api/v1/tickets/gitlab-proxy/projects/${id}/repository/commits`, {
                params: {
                    order: 'default',
                    per_page: 100,
                    with_stats: true,
                    until,
                    ref_name: branch
                }
            })
                .then(resp => {
                    const { data } = resp.data;
                    accumulated = accumulated.concat(data);
                    setFetchedBranch(branch);
                    setCommits(accumulated);
                    if (data.length < 100) {
                        fetched = fetched.concat([projectId]);
                        setFetchedList(fetched);
                        if (projectIdList.length > 0) {
                            fetchData(projectIdList.shift(), moment().startOf('hour').format(FMT), resolve, reject);
                            return;
                        }
                        return resolve(accumulated);
                    }
                    setTimeout(() => {
                        fetchData(projectId, moment(data[data.length - 1].committed_date).format(FMT), resolve, reject);
                    }, 100);
                }).catch(ex => {
                    console.error(ex);
                    reject(ex);
                });
        }
        return new Promise((resolve, reject) => {
            fetchData(projectIdList.shift(), moment().startOf('hour').format(FMT), resolve, reject)
        })
    };

    const handleSelect = (e) => {
        const nList = selectedList.filter(item => item !== e.target.name);
        if (e.target.checked) {
            nList.push(e.target.name);
        }
        setSelected(nList)
    }

    useEffect(() => {
        iAxios.get('/api/v1/tickets/projects')
            .then((resp) => {
                setProjectList(resp.data.sort((a, b) => a.name.localeCompare(b.name)));
            }).catch(ex => {
                console.error(ex);
            });
    }, [1])

    const handleFind = (e) => {
        e.preventDefault();
        setShowDropdown('');
        setLoading(true);
        setFetchedList([]);
        setCommits([]);
        fetchCommits().then(data => {
            data.sort((a, b) => {
                return moment(a.committed_date).toDate().getTime() - moment(b.committed_date).toDate().getTime();
            });
            setCommits(data);
            setLoading(false);
        }).catch(ex => {
            console.error(ex);
            alert('Error while fetching commits');
            setLoading(false);
        });
    };


    return <div >

        <form className="w3-row-padding w3-margin-16" onSubmit={e => handleFind(e)} >
            <div className="w3-col m4">
                <input className="w3-input w3-border w3-round"
                    onFocus={e => {
                        e.stopPropagation();
                        setShowDropdown('w3-show')
                    }}
                    placeholder="Gitlab project ids"
                    onChange={e => setProjects(e.target.value)} />
                <div onClick={e => e.stopPropagation()} className={`${showDropdown} w3-dropdown-content   w3-light-grey w3-bar-block w3-border  `} style={{ maxHeight: '500px', overflow: 'scroll' }}>
                    {projectList
                        //    .filter(item => !selectedList.some(s => s === item.fullPath))
                        .filter(item => item.fullPath.toLowerCase().indexOf(projects.toLowerCase()) >= 0)
                        .map((item, idx) => {
                            return <label className="w3-bar-item w3-button">
                                <input name={item.fullPath} type="checkbox"
                                    className="w3-checkbox w3-margin-right "
                                    checked={selectedList.some(s => s === item.fullPath)}
                                    onChange={handleSelect} />
                                <span >{item.name}</span>
                                <span className="w3-right w3-margin-left">{idx + 1}</span>
                                <span className="w3-tiny w3-margin-left w3-right">
                                    {item.fullPath}
                                </span>
                            </label>
                        })}
                </div>
            </div>
            <div className="w3-col m3">
                <input className="w3-input w3-border w3-round"
                    placeholder="branch"
                    onChange={e => dbSetBranch(e.target.value)}
                />
            </div>
            <div className="w3-col m3">
                <input className="w3-input w3-border w3-round"
                    onFocus={e => setShowDropdown('')}
                    placeholder="email"
                    onChange={e => dbSetEmail(e.target.value)}
                />
            </div>
            <div className="w3-col m2">
                <button className={`w3-button w3-round w3-padding w3-green`}
                    disabled={selectedList.length === fetchedList.length && fetchedBranch === branch}
                >Submit</button>
                {selectedList.length > 0 && <span className="w3-large w3-padding ">{Math.floor(fetchedList.length * 100 / selectedList.length)}%</span>}
            </div>
        </form>
        <div onClick={e => setShowDropdown('')} className="w3-center w3-padding w3-margin-bottom w3-row" >
            {projectList.filter(item => selectedList.some(s => s === item.fullPath))
                .map(item => <label key={item.id} className="w3-padding-small w3-left ">
                    <span className={`w3-label w3-padding-small w3-align-left w3-block w3-border w3-round ${fetchedList.some(s => s === item.id) ? 'w3-pale-blue w3-border-blue' : ''}  `}>
                        <input type="checkbox" className="w3-margin-right"
                            name={item.fullPath}
                            onChange={handleSelect}
                            checked={true} />
                        {item.name}
                        <div className="w3-tiny">{item.fullPath}</div>
                    </span>
                </label>)
            }
        </div>
        <RenderContrib commits={commits} email={email} />
        {/* {useMemo(()=>  <RenderContrib commits={commits} email={email} />,[email,commits])} */}
        {loading && <div className="w3-center"><Spinner /></div>}
    </div>
}
