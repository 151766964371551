import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";

export const GenericForm = (props) => {
    const { fields,
        fieldClass = '',
        initVals = {},
        onSubmit,
        cancelLabel = 'Cancel',

        submitLabel = 'Save',
        submitOptions,
        onCancel,
        cancelOptions = {
            className: 'w3-black w3-button w3-round w3-padding-small'
        },
        wrapClass,
        chidlren,
        formClass } = props;

    const [formFields, setFormFields] = useState([]);

    useEffect(() => {
        if (!Array.isArray(fields)) {
            return;
        }
        const data = fields.map((item) => {
            let defaultValue = null;
            if (initVals[item.name]) {
                defaultValue = initVals[item.name];
                if (item.type === 'date') {
                    defaultValue = moment(initVals[item.name]).format('YYYY-MM-DD');
                }
            }
            return Object.assign({ defaultValue }, item);
        })
        setFormFields(data)
    }, [fields, initVals])


    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }
        if (onSubmit) {
            onSubmit(data);
        }
    }

    return <form onSubmit={handleSubmit} className={formClass}>
        {formFields.map((item, idx) => {
            let formElem = null;
            const { element, labelOptions, ...inputOptions } = item;
            if (!inputOptions.name) {
                return (<div>Missing name field for item # {idx}</div>)
            }

            inputOptions.className = classNames(fieldClass, item.className); 
            if (element === 'input' || !element) {
                formElem = <input  {...inputOptions}
                />
            } else if (element === 'select') {
                const { options, ...selectOptions } = inputOptions;
                formElem = <select {...selectOptions}  >
                    {item.options?.map(opt => {
                        return <option key={opt.value} value={opt.value}>
                            {opt.label}
                        </option>
                    })}
                </select>
            } else {
                formElem = <input {...item} />
            }
            return <div className={ item.wrapClass ? item.wrapClass: wrapClass} key={`input-${idx}`}>
                {item.label && <label {...labelOptions}>{item.label}</label>}
                {formElem}
            </div>
        })}
        {chidlren}
        <div className='w3-row'>
            <div className='w3-col m6 w3-align-left'>
                {onCancel && <button onClick={onCancel} type="button" {...cancelOptions}> {cancelLabel}</button>}
                &nbsp;
            </div>
            <div className='w3-col m6 w3-right-align'>
                <button type="submit" {...submitOptions}> {submitLabel}</button>
            </div>

        </div>
    </form>
}

