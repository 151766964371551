import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import { useMilestone } from '../../lib/hooks';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { alertMessage } from '../../lib/actions';
import classNames from 'classnames';

const dt = new Date();
dt.setMonth(dt.getMonth() - 1)
export const MilestoneSwitch = () => {

    const [showList, setShowList] = useState(true);
    const { milestoneList, modalStatus } = useSelector(st => ({
        milestoneList: st.milestoneList,
        modalStatus: st.modalStatus
    }));
    const [value, setValue] = useState([dt, new Date()]);
    const [sp, setSearchParams] = useSearchParams();

    const milestone = useMilestone();
    useEffect(() => {
        if (!milestone) {
            return
        }
        setValue([
            moment(milestone.start).toDate(),
            moment(milestone.end).toDate(),
        ])
    }, [milestone])
    const dispatch = useDispatch();
    const toggleFilter = () => {
        dispatch({
            type: 'popup',
            name: modalStatus.popupName !== 'milestone-switch' ? 'milestone-switch' : ''
        })
    }


    const onChange = (value) => {
        if (!value) {
            const nsp = new URLSearchParams(document.location.search);
            nsp.delete('start')
            nsp.delete('end')
            setSearchParams(nsp)
            return;
        }
        const start = moment(value[0]);
        const end = moment(value[1]);
        console.log('days : ', start.diff(end, 'day'))
        if (end.diff(start, 'days') > 365) {
            dispatch(alertMessage('You can check stats for a maximum duration of 365 days', 'Error', 'w3-red'));
            return;
        }
        const nsp = new URLSearchParams(document.location.search);
        nsp.set('start', start.format('YYYY-MM-DD'))
        nsp.set('end', end.format('YYYY-MM-DD'))
        // setValue(value)
        setSearchParams(nsp)
    }


    const np = new URLSearchParams(document.location.search)

    return <div className='w3-show-inline-block' style={{ position: "relative", background: 'transparent' }} >
        <button className="w3-button w3-theme-l1 w3-round" onClick={toggleFilter}>{milestone?.title}
            <span className="ion-chevron-down" style={{
                fontSize: "10px",
                marginLeft: "10px"
            }}></span>
        </button>
        <div className={classNames("w3-dropdown-content w3-bar-block w3-border", {
            'w3-show': modalStatus.popupName === 'milestone-switch'
        })}
            style={{ right: "0px", height: "400px", maxHeight: '400px', width: '240px', overflow: 'auto' }}>
            {/* <button onClick={e => selectRange(e)} className='w3-button w3-block'>Custom Dates</button> */}
            <div className='w3-padding w3-center'>
                <DateRangePicker
                    onCalendarClose={e => setShowList(true)}
                    onCalendarOpen={e => setShowList(false)}
                    onChange={onChange}
                    value={value} maxDate={moment().add(15, 'day').toDate()} />
            </div>

            {showList && milestoneList?.map(item => {
                np.set('start', item.start)
                np.set('end', item.end)
                return <Link key={item.id}
                    to={`${document.location.pathname}?${np.toString()}`} className={`w3-bar-item cgs-list-milestone  w3-button ${item.state == 'active' ? '' : 'w3-blue-grey'} `}>{item.title}
                    <span className="w3-tiny  w3-right w3-purple w3-round" title={`${item.start_date} - ${item.due_date}`}>{moment(item.start_date).format("DD")}-{moment(item.due_date).format("DD")}</span>
                </Link>
            })}
        </div>
    </div>
}
