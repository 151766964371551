import React, { Fragment, useEffect, useState } from "react";
import { iAxios } from "../../lib/utils";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { alertMessage, fetchUserInfo } from "../../lib/actions";


const UsersStatus = ({ user }) => {

  const [query, setQuery] = useState('');
  const [loc, setLoc] = useState(null);
  const dispatch = useDispatch();
  const { userInfo } = useSelector(st => ({
    userInfo: st.userInfo
  }));

  useEffect(() => {
    console.log(query)
  }, [query])

  useEffect(() => {
    const timer = setInterval(() => {
      fetchUserInfo(false);
    }, 60 * 1000);
    return () => {
      clearInterval(timer)
    }
  }, [1])

  useEffect(() => {
    let icons = ['✅','🚀','🎯'];
    let info = userInfo.checkinStatus;
    if (userInfo.checkinStatus === 'Out') {
      icons = ['❌','🚫','🛑']
    }
    if (userInfo.checkinStatus === 'In' && Date.now() - userInfo.heartbeatTm > 300e3 && userInfo.autoCheckoutSec > 0) {
      icons = ['❗'];
      info = 'No Update';
    }
    icons.sort((a,b) => Math.random() - Math.random());
    document.title = `${icons[0]}  Zcalo (${info})`;
  }, [userInfo])

  useEffect(() => {

    const qs = new URLSearchParams()
    const token = localStorage.getItem('access_token');
    qs.set('token', token);
    qs.set('server', document.location.origin);

    if (!navigator.geolocation) {
      setQuery(qs.toString())
      return;
    }

    navigator.geolocation.getCurrentPosition((data) => {
      qs.set('lat', data.coords.latitude);
      qs.set('lon', data.coords.longitude);
      qs.set('acc', data.coords.accuracy);
      setQuery(qs.toString())
      setLoc({
        lat: data.coords.latitude,
        lon: data.coords.longitude,
        acc: data.coords.accuracy
      })
    }, (err) => {
      console.error(err);
      dispatch({
        type: 'show-modal',
        name: 'locationError',
        data: `You need to enable location Permission to Checkin`
      })
    });
  }, [userInfo?.email])

  const handleInOut = async (e) => {

    const prevStat = userInfo.checkinStatus;
    const resp = await dispatch(fetchUserInfo())
    if (prevStat !== resp.data.user.checkinStatus) {
      return;
    }

    const data = Object.assign({
      action_group: 'In',
      action: 'Punch In',
    }, loc);

    if (userInfo.checkinStatus === 'In') {
      data.action_group = 'Out';
      data.action = 'Punch Out'
    }
    

    iAxios.post('/api/v1/user-log', data).then(resp => {
      console.log(resp.data);
      if (!resp.data.success) {
        dispatch(alertMessage(resp.data.message, 'Error While Checkin'))
      }
      dispatch({
        type: 'SET_USER_INFO',
        data: resp.data.data.user
      });
    })
  }

  return (
    <Fragment>
      <span style={{ marginTop: '4px' }} className="w3-hide-small">
        {userInfo.data && <span className="w3-margin-left">{userInfo.data.status}
          <span className="w3-text-aqua w3-padding-small">({moment.unix(userInfo.data.statusTime / 1000).fromNow()}
            )</span></span>}
      </span>
      {parseInt(userInfo.autoCheckoutSec) !== -1 && query !== '' && <a className='w3-button w3-theme-l1 w3-round w3-margin-right'
        href={`zcalo://login/?${query}`}>
        {(userInfo.heartbeatTm < Date.now() - 12e4 && userInfo.checkinStatus === 'In') ? 'Open Copilot' : 'Copilot Checkin'}
      </a>}
      {parseInt(userInfo.autoCheckoutSec) === -1 && query !== '' && <button onClick={handleInOut}
        className='w3-button w3-theme-l1 w3-round w3-margin-right' title={userInfo.checkinStatus}>
        {userInfo.checkinStatus === 'Out' ? 'Punch In' : 'Punch Out'}
      </button>}
    </Fragment>
  );
}

export default (UsersStatus);
