import { useEffect, useState } from "react";
import moment from 'moment';

import { fetchData } from "../../lib/utils";

const FinRow = (props) => {
    const { isAfter, content, idx } = props;
    const [isFull, showFull] = useState(false);
    const inital = content.split('\n')
    const res = inital.reduce((agg, item) => {
        if (item.indexOf('-') == 0 && isFull == false && inital.length > 1) {
            return agg;
        }
        agg.push(item);
        return agg;
    }, []);

    return <td>
        {inital.length > 1 ? <div style={{ whiteSpace: 'pre-wrap' }}>
            {res.join('\n')}
        </div> : <div>{res.join('')}</div>}
        {(inital.length != res.length || isFull) && <button onClick={e => showFull(!isFull)}
            className='w3-button w3-margin-top w3-center w3-padding-small w3-border w3-round w3-tiny ' >
            {isFull ? 'Show Less' : 'Show More'}
        </button>}
        {(idx == 0 && isAfter) && <div>Projection</div>}
    </td>
}


const Financials = (props) => {

    const [finData, setFindAta] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [total, setTotal] = useState(null);
    useEffect(() => {
        fetchData('/api/v1/utils/fins', {}).then(resp => {
            setFindAta(resp.data);
            const sum = resp.data.reduce((total, item) => {
                // console.log(item['Balance'],total)
                // return total + parseFloat(item['Balance'].replace(',',''), 10)

                return 0;
            }, 0)
            // setTotal(sum)
            setHeaders(Object.keys(resp.data[0]))
        })
    }, [])
    return <div className='w3-padding w3-white'>
        <div>
            <h3 className='w3-left'>Utilisation</h3>
            {total !== null && <h6 className='w3-right'>Bonus Projection : {Math.floor(total)}</h6>}
        </div>
        <table className='w3-table  w3-border  w3-striped'>
            <thead >
                <tr className=' w3-leftbar w3-border'>
                    {headers.map(item => <th className='w3-bold'><b>{item}</b></th>)}
                </tr>
            </thead>
            <tbody>
                {finData.map((row) => {
                    const isAfter = moment(row['Month']).endOf('M').isAfter(Date.now())
                    return <tr className={isAfter ? 'w3-border-yellow w3-leftbar' : 'w3-leftbar w3-border-blue'}>
                        {headers.map((item, idx) => <FinRow idx={idx} content={row[item]} isAfter={isAfter} />)}
                    </tr>
                }
                )}
            </tbody>
        </table>

    </div>
}

export default Financials;
