import React, { useEffect, useState } from 'react'
import { Spinner, TabFilter } from '../common/Helper';

import { useConf, useTicketGrouper } from '../../lib/hooks';
import { IssueGroupView } from '../perf/IssueGroup';
import { iAxios } from '../../lib/utils';

const Status = () => {

    
    const { groupedList, filter } = useTicketGrouper()
    const [filters, setFilters] = useState([]);


    const { workflow } = useConf();

    useEffect(() => {
        setFilters(['All', 'Pending', ...workflow]);
    }, [workflow])

    let content = null,
        total = 0;


    if (groupedList == null) {
        content = <Spinner />;
    } else if (groupedList.length === 0) {
        content = <div className="w3-center w3-padding-64 w3-block" >
            Empty Result
        </div>
    } else {
        content = groupedList.reduce((acc, item) => { 
            item.filter(filter)
            const view = <IssueGroupView key={item.uname} group={item} filter={filter} />
            acc.push(view);
            return acc;
        }, []);


        if (content.length === 0) {
            content = <div className="w3-center w3-light-grey w3-padding-64 w3-block" >
                Current selection has no items to show.
            </div>
        } else {
            content = <div className="cgs-table cgs">
                {content}
            </div>
        }
        total = groupedList.reduce((acc, item) => acc + item.filtered.length, 0);
    }

    return (<div>
        <TabFilter filters={filters}
            name='filter'
            count={total}
        />
        <div className="w3-responsive w3-margin-top" style={{ minHeight: "100vh" }}>
            {content}
        </div>
    </div>)

}

export default Status;