import { useEffect } from "react";
import { useTeamList } from "../../lib/hooks";
import { useState } from "react";
import classNames from "classnames";

const { useSelector, useDispatch } = require("react-redux");


const LeadFilter = ({ teamList, lead }) => {
    const dispatch = useDispatch();
    const userFilter = useSelector(st => st.tickets.userFilter)

    const toggleItem = (e) => {
        const email = e.target.value;
        const user = teamList.find(item => item.email === email);
        let userList = []
        if (!userFilter.some(item => item.email === user.email)) {
            userList = [...userFilter, user]
        } else {
            userList = userFilter.filter(item => item.email !== email);
        }
        dispatch({
            type: 'filter_users',
            users: userList
        });

    }

    const changeTree = (path) => {
        // const email = e.target.value;
        console.log('toggling ', path);
        let userList = [];
        const user = teamList.find(item => item.path === path);
        const team = teamList.filter(item => item.path.indexOf(path) >= 0);

        if (!userFilter.some(item => item.email === user.email)) {
            userList = [...userFilter, user, ...team]
        } else {
            userList = userFilter.filter(item => item.path.indexOf(path) === -1);
        }
        dispatch({
            type: 'filter_users',
            users: userList
        });
    }
    return <div style={{ padding: "0px 2px 0px 12px" }}>
        {teamList.filter(item => item.teamLead === lead).map(item => {
            const showChildren = userFilter.some(uf => uf.path.indexOf(`#${item.username}#`) >= 0 || uf.email == item.email)
            // const hasChildren = teamList.some(u => u.teamLead == item.email);
            const count = teamList.filter(u => u.path.indexOf(`${item.username}#`) > 0).length;
            return <div key={item.username} title={`${item.email}-${lead}`}>
                <input onChange={toggleItem} value={item.email} title={`Toggle ${item.name} `}
                    className="" style={{ marginRight: '8px' }} type="checkbox"
                    checked={userFilter.some(uf => uf.email === item.email)} />
                <label title={`Toggle immediate team of ${item.name}`}
                    onClick={e => changeTree(item.path)} >{item.name}
                    {count > 0 ? `  (${count + 1})` : ''}
                    {<i className={classNames(" w3-margin-left", {
                        'ion-arrow-right-b': !showChildren && count > 0,
                        'ion-arrow-down-b': showChildren && count > 0
                    })} />}
                </label>
                {showChildren && <LeadFilter key={item.email}
                    teamList={teamList} lead={item.email} />}
            </div>
        }
        )}
    </div>
}

export const TeamFilter = () => {
    const dispatch = useDispatch();
    const teamList = useTeamList([]);
    const { userFilter, modalStatus } = useSelector(st => ({
        userFilter: st.tickets.userFilter,
        modalStatus: st.modalStatus,
    }))

    const toggleFilter = () => {
        dispatch({
            type: 'popup',
            name: modalStatus.popupName !== 'team-filter' ? 'team-filter' : ''
        })
    }
    const clearFilter = () => {
        dispatch({
            type: 'filter_users',
            users: [],
        })
    }

    return <span className={` w3-round w3-margin-right`}
        style={{ maxWidth: '200px', position: 'relative' }}>
        <button className={`w3-button w3-round w3-theme-l1 `}
            title={`Team filter`} onClick={toggleFilter}
        >
            <i className="ion-android-funnel" />
        </button>
        <div className={classNames("w3-dropdown-content w3-padding w3-row w3-left-align w3-bar-block w3-border ", {
            'w3-show': modalStatus.popupName === 'team-filter'
        })}
            style={{ maxWidth: '200px', maxHeight: '80vh', overflow: 'scroll', right: '0px' }}>
            {<div className="w3-padding-small w3-right-align">
                <button className="w3-button w3-blue w3-padding-small w3-round  "
                    disabled={userFilter.length === 0}
                    onClick={e => clearFilter()}>Clear People Filter
                    {userFilter.length > 0 && <span style={{ marginLeft: '6px' }}>({userFilter.length})</span>}</button>
            </div>}
            <LeadFilter key="null" teamList={teamList} lead={''} />
            <LeadFilter key="empty" teamList={teamList} lead={null} />
        </div>
    </span>
}


