import React, { useEffect, useState } from 'react'; 
import UsersStatus from './UsersStatus'; 
import { TeamFilter } from './TeamFilter';
import { MilestoneSwitch } from '../common/MilestoneSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { actionFetchIssues } from '../../lib/actions';
import { useMilestone } from '../../lib/hooks'; 

const Header = () => { 

    const milestone = useMilestone(); 


    const dispatch = useDispatch(); 

    const {userInfo,fetchInProgeress} = useSelector(st => ({
        userInfo: st.userInfo,
        fetchInProgeress: st.tickets.ticketList === null
    }));
 

    return (<div className="w3-theme w3-container w3-row header-container" >
        <div className="w3-right w3-col m12 w3-right-align w3-padding">
            <UsersStatus  user={userInfo} /> 
            <TeamFilter   />
            <button 
                    disabled={fetchInProgeress}
                    onClick={e => dispatch(actionFetchIssues(milestone,'refresh'))}
                    className="w3-margin-right w3-theme-l1 w3-bar-item w3-button w3-round">
                    <i className="ion-refresh"></i>
            </button>
            <MilestoneSwitch/>
        </div>
        
    </div>);
};
 
export default (Header);