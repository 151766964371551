import React, { useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux'
import configureStore from './lib/configureStore';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import './scss/style.scss';


import Layout from './components/common/Layout';
import { NotFound, CopLogin } from './components/common/Helper';

import Status from './components/pages/Status';
import Member from './components/pages/Member';
import TeamActivity from './components/pages/TeamActivity';
import Project from './components/pages/Project';
import Client from './components/pages/Client';
import Login from './components/pages/Login';
import Leaves from './components/pages/Leaves';
import IssueBoard from './components/pages/IssueBoard';
import Dashboard from './components/pages/Dashboard';
import ResumeSearch from './components/pages/ResumeSearch';
import Financials from './components/pages/Financials';
import UserActivity from './components/pages/UserActivity';

import { Jira } from './components/perf/Jira';
import { ProjectContrib } from './components/pages/ProjectContrib';
import Poker from './components/pages/Poker';
import DocMarkdown from './components/pages/DocMarkdown';
import Settings from './components/pages/Settings';
import { AdminDashboard } from './components/pages/Dashboard/Admin';

const router = createBrowserRouter(
    [
        {
            path: '/login/:provider?',
            element: <Login />,
        },
        {
            path: '/pages',
            element: <DocMarkdown hasHeaderFooter={true} />
        },
        {
            path: "/",
            element: <Layout />,
            children: [
                {

                    path: "/jira/:projectId/:componentId?/:page?",
                    element: <Jira />
                },
                {
                    path: "/leaves",
                    element: <Leaves />
                },
                {
                    path: "/dashboard",
                    element: <Dashboard />
                },
                {
                    path: "/project/:project",
                    element: <Project />
                },
                {
                    path: "/admin-dashboard/",
                    element: <AdminDashboard />
                },
                {

                    path: "/client/",
                    element: <Client />
                },
                {

                    path: "/member/:username?",
                    element: <Member />
                },
                {
                    path: "/contrib",
                    element: <ProjectContrib />
                },
                {

                    path: "/my-team/",
                    element: <TeamActivity />
                },
                {

                    path: "/my-day/:username?",
                    element: <UserActivity />
                },
                {
                    path: "/utilisation",
                    element: <Financials />
                },
                {

                    path: "/my-tickets",
                    element: <Member />
                },
                {
                    path: '/team-status',
                    element: <Status />
                },
                {
                    path: '/copilot-login',
                    element: <CopLogin />
                },
                {
                    path: '/board',
                    element: <IssueBoard />
                },
                {
                    path: '/resume',
                    element: <ResumeSearch />
                },
                {
                    path: '/docs',
                    element: <DocMarkdown />
                },
                {
                    path: '/poker/:product',
                    element: <Poker />
                },
                {
                    path: '/settings/:tab?',
                    element: <Settings />
                }
            ],
        },
        {
            path: "*",
            element: <NotFound />
        }
    ], {
    future: {
        v7_relativeSplatPath: true,
        v7_startTransition: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_fetcherPersist: true,
        v7_skipActionStatusRevalidation: true,
        v7_skipActionErrorRevalidation: true
    }
});

const store = configureStore()
window.gAppStore = store;
const root = createRoot(document.querySelector('#app'));

const App = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        window.addEventListener("storage", (event) => { 
            if (event.key === 'user') {
                dispatch({
                    type: 'UPDATE_USER_INFO',
                    data: JSON.parse(event.newValue)
                })
            }
            if (event.key === 'access_token') {
                document.location.href = '/';
            }
        });
    }, [])
    return props.children
}
root.render(<Provider store={store} >
    <App>
        <RouterProvider router={router} />
    </App>
</Provider>)
