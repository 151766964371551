import { useEffect, useState } from "react";
import { iAxios } from "../../lib/utils";

export const BlogStatus = ({ userList }) => {
    const [blogData, setBlogData] = useState([])
    let total = 0;
    useEffect(() => {
        iAxios.get('/api/v1/utils/blog-status').then(res => {
            setBlogData(res.data)
        })
    }, [1])

    const rows = blogData.map((item, idx) => {
        total += item.posts?.nodes?.length
        return <tr key={item.id} key={item.name}>
            <td>{idx + 1}</td>
            <td>
                <a href={`https://blog.ceegees.in${item.uri}`} target='_blank' rel="noreferrer" > {item.name}</a>
            </td>
            <td>{item.posts?.nodes?.length}</td>
        </tr>
    })

    return <div className=" w3-white w3-margin-bottom" style={{ minWidth: "320px" }}>
        <h4 className="w3-center  w3-padding-small"  >Blog Posts : {total}
        </h4>
        <table className='w3-table w3-striped w3-border'>
            <tbody>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Posts </th>
            </tr>
            {rows}
            </tbody>
        </table>
    </div>
}