import moment from "moment";
import { useEffect, useState } from "react";
import { formatSecs, iAxios, STATUS_COLOR_MAP } from "../../lib/utils";
import { Spinner } from "../common/Helper";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { alertMessage } from "../../lib/actions";
import classNames from "classnames";

const MOD_TM = 3e5 / 2;

const UserActivity = () => {
    const dispatch = useDispatch()
    let { username } = useParams();
    const [data, setData] = useState(null);
    const [engagement, setEngagement] = useState([]);
    const [logs, setLogs] = useState([]);
    const [maxPage, setMaxPage] = useState(36);
    const userInfo = useSelector(st => st.userInfo);
    const [error, setError] = useState(null);

    const [sp, setSearchParams] = useSearchParams();
    if (!username) {
        username = userInfo.username;
    }

    useEffect(() => {
        setData(null);
        let date = sp.get('date');
        if (!date) {
            setSearchParams((prev) => {
                prev.set('date', moment().format('YYYY-MM-DD'));
                return prev;
            })
            return
        }
        iAxios.get('/api/v1/track/user-activity', {
            params: {
                offset: new Date().getTimezoneOffset(),
                date,
                username
            }
        }).then(resp => {
            const user = resp.data.user;
            if (!resp.data.success) {
                setData([]);
                setEngagement([])
                setError(resp.data.message)
                return;
            }

            const { data: list, logs } = resp.data;
            setLogs(logs)
            if (list.length === 0) {
                setData([])
                setEngagement([])
                return;
            }
            list.sort((a, b) => b.tm - a.tm);
            setData(list);
            let last = list.length - 1;
            const startMin = Math.floor(list[last].tm / MOD_TM);
            const eng = [];
            const max1 = startMin + Math.floor((36e5 * 9.5) / MOD_TM);
            const max = Math.floor(Date.now() / MOD_TM);
            for (let min = startMin; min <= max1;) {
                const m1 = Math.floor(list[last].tm / (MOD_TM));
                let dec = false;
                const tmStr = moment(min * MOD_TM).format('hh:mm a');
                if (m1 < min) {
                    dec = true;
                } if (m1 > min) {
                    dec = false;
                    eng.push({
                        min,
                        val: 0,
                        tmStr,
                        cls: 'w3-black'
                    });
                } else if (m1 === min) {
                    eng.push({
                        min,
                        val: 1,
                        tmStr,
                        url: list[last].url,
                        cls: 'w3-green'
                    });
                    dec = true;
                    min++;
                }

                if (dec && last > 0) {
                    last--;
                } else {
                    min++;
                    eng.push({
                        min,
                        tmStr,
                        cls: min < max ? 'w3-black' : 'w3-yellow',
                        val: 0
                    });
                }
            }
            setEngagement(eng);
        })
    }, [1, sp, username]);

    const increaseMax = () => {
        setMaxPage(maxPage + 60);
    }

    const analyzeDay = () => {
        iAxios.post('/api/v1/track/analyse-day', {
            username,
            data,
            logs
        }).then(resp => {
            console.log(resp);
        })
    }

    let date = sp.get('date');
    return <div className='w3-row'>
        <div className="w3-col 12 w3-align-right w3-padding">
            <div className="w3-col m8  w3-left-align"><div className="w3-xxlarge">{username}</div></div>
            <div className="w3-col m4 w3-right-align">
                <input type="date" className=' w3-input w3-right'
                    max={moment().format('YYYY-MM-DD')} style={{ maxWidth: '200px' }}
                    defaultValue={moment(date).format('YYYY-MM-DD')} onChange={e =>
                        setSearchParams((prev) => {
                            prev.set('date', moment(e.target.value).format('YYYY-MM-DD'));
                            return prev;
                        })} />
            </div>
        </div>
        <div className='w3-padding w3-container w3-center'>
            {!data && <Spinner />}
            <div className="row">
                {data?.length > 0 && <div className='activity-scroller w3-col m12 w3-margin-bottom'>
                    <table>
                        <tbody>
                            <tr>
                                {engagement.map((item, idx) => <td
                                    key={`idx-${idx}`}
                                    title={`${item.tmStr}`}
                                    className={`activity-cell ${item.cls}`}>
                                    {item.url && <img src={item.url} className='hover-img' alt={item.tmStr} />}
                                    <div className='hover-text w3-black'>{item.tmStr}</div>
                                </td>)}
                            </tr>
                        </tbody>
                    </table>
                </div>}
                <div className="w3-col m9 l9 w3-left-align scrollable" >
                    {error && <h3 className="w3-text-red ">{error}</h3>}
                    {data && data.length === 0 && error == null && <div className="w3-center">
                        No Screens recorded for the date  {moment(date).format('DD MMM YYYY')}
                    </div>}
                    {data && data?.filter((item, idx) => idx < maxPage).reduce((acc, item) => {
                        acc.push(<div key={item.tm}
                            className={classNames('w3-show-inline-block  w3-round ')}
                            style={{ margin: '2px', border: 'solid 2px ', position: 'relative' }}>
                            <a href={item.full} rel="noreferrer" target='_blank' alt="view in new tab">
                                <img src={item.url} alt="screenshot " onError={(e) => e.target.src = '/missing.png'} className="screenshot" />
                            </a>
                            {userInfo.role === 'Admin' && <span style={{ position: 'absolute', top: '10px', right: '10px', height: '16px', width: '16px' }} className={classNames('w3-circle w3-inline-block', {
                                'w3-red': item.diff <= 1000,
                                'w3-green': item.diff > 1000
                            })}></span>}
                            <div className='w3-center'>
                                {moment(item.tm).format('hh:mm a')}
                            </div>
                        </div>)
                        return acc;
                    }, [])}
                    &nbsp;
                    <div className="w3-col m12 l12">
                        {maxPage < data?.length && <div className='w3-center w3-margin'>
                            <button className='w3-button w3-round w3-border w3-black' onClick={increaseMax}>
                                Showing {maxPage}/ {data.length}  Show More</button></div>}
                    </div>
                </div>
                <div className="w3-col m3 l3 scrollable" >
                    {(data && data.length > 0 && userInfo.role === 'Admin') && <button onClick={analyzeDay} className="w3-margin w3-button w3-blue w3-round w3-padding-small">Analyse my day</button>}
                    {logs.map(item => {
                        const color = STATUS_COLOR_MAP[item.action_group];
                        const at = parseInt(`${item.action_time}`);
                        const dur = parseInt(`${item.action_duration}`)
                        // if(item.action_group === 'Out' && moment(at).format('DD') !== moment(at+dur).format('DD')){
                        //     return <span></span>
                        // }
                        return <div title={`ms ${item.action_duration}`} key={item.id}
                            className={`${color} w3-padding w3-round w3-left-align w3-margin-bottom`}>
                            <label>
                                <input type="checkbox" className="w3-radio w3-hide w3-right">
                                </input>
                                Type : {item.action_group}<br />
                                Action : {item.action}<br />
                                {item.screens > 0 && <div>Tracked : {formatSecs(item.screens * 60)}</div>}
                                Duration : {formatSecs(dur / 1000)} <br />
                                From: {moment(at).format('DD MMM hh:mm a')} to : {moment(at + dur).format('DD MMM hh:mm a')} <br />
                            </label>
                        </div>
                    })}
                </div>

            </div>

        </div>
    </div>
}


export default UserActivity;