import React, { Component } from 'react' 
import { Spinner, TRWrapper } from '../common/Helper';
import { formatSecs, paginatedFetch } from '../../lib/utils' 
import BurnDown from '../perf/BurnDown';
import IssueGroup from '../perf/IssueGroup';
import IssueBoard from '../pages/IssueBoard';

const TagCloud = ({ tags, tagStatus, parent }) => {
    let list = [];
    for (var name in tags) {
        let cls = 'w3-blue-grey';
        if (tagStatus[name]) {
            cls = 'w3-green';
        }
        list.push(<button id={name} key={name}
            onClick={parent.onTagClick.bind(parent, name)}
            style={{ margin: "2px" }} className={`${cls} w3-button w3-padding-small w3-round w3-small  w3-tag `}> {name}
            <span className="w3-deep-orange w3-tag w3-round" style={{ marginLeft: "4px" }}> {tags[name].tasks}</span>
            <span className="w3-blue w3-tag w3-round" style={{ marginLeft: "4px" }}> {formatSecs(tags[name].spent)}</span>
        </button>)
    }
    list = list.sort((a, b) => a.key.localeCompare(b.key));
    return list;
}

const TimePersonTable = (props) => {
    const { total, people, error, message } = props;
    let content = <Spinner />

    const list = [];
    for (var name in people) {
        list.push(<tr key={name}>
            <td>{name}</td>
            <td>{formatSecs(people[name].totalSpent)} / {formatSecs(people[name].totalEstimate)}</td>
        </tr>);
    }

    if (error) {
        list.push(<TRWrapper key="error" colSpan="2">
            <div className="w3-red w3-padding w3-center">
                <h6>{error}</h6>
            </div>
        </TRWrapper>)
    } else if (message) {
        list.push(<TRWrapper key="message" colSpan="2">
            <div className="w3-padding w3-center">
                <h6>{message}</h6>
            </div>
        </TRWrapper>)
    } else if (list.length == 0) {
        list.push(<TRWrapper key="loading" colSpan="2"><Spinner /></TRWrapper>);
    }

    return <table className="w3-table  w3-table-all">
        <thead>
            <tr>
                <th>Person</th>
                <th>Time
                    <span className="cgs-muted">{formatSecs(total)}</span>

                </th>
            </tr>
        </thead>
        <tbody>{list}</tbody>
    </table>

}

class Client extends Component {

    constructor(arg) {
        super(arg);
        this.state = {
            issueList: null,
            filter: '',
            tagStatus: {},
            error: null
        }
    }
    filteredIssues() {
        let newList = this.state.issueList;
        if (!newList) {
            return {
                tags: {},
                issues: null,
                total: 0,
                people: {},
            };
        }
        let { tagStatus, filter } = this.state;
        filter = filter.toLowerCase();
        const tags = newList.reduce((acc, item) => {
            item.labels.forEach(label => {
                if (!acc[label]) {
                    acc[label] = {
                        tasks: 0,
                        spent: 0
                    }
                }
                acc[label].tasks++;
                acc[label].spent += item.timeSpent;
            });
            return acc;
        }, {});

        if (Object.keys(tagStatus).length != 0) {
            newList = newList.filter(item => item.labels.some(s => tagStatus[s]))
        }
        if (filter != '') {

            if (filter.indexOf("!") == 0) {
                filter = filter.replace("!", "");
                newList = newList.filter(item => {
                    return item.title.toLowerCase().indexOf(filter) < 0 &&
                        item.assignee.name.toLowerCase().indexOf(filter) < 0 &&
                        item.labels.join().toLowerCase().indexOf(filter) < 0
                });
            } else {

                newList = newList.filter(item => {
                    return item.title.toLowerCase().indexOf(filter) >= 0 ||
                        item.assignee.name.toLowerCase().indexOf(filter) >= 0 ||
                        item.labels.join().toLowerCase().indexOf(filter) >= 0;
                });
            }
        }

        newList = newList.sort((a, b) => {
            const abase = a.state == 'closed' ? 1000 * 86400 : 0;
            const bbase = b.state == 'closed' ? 1000 * 86400 : 0;
            return (abase) - (bbase);
        });

        const total = newList.reduce((acc, item) => acc + parseInt(item.timeSpent), 0);

        const map = newList.reduce((acc, item) => {
            if (!item.assignee) {
                return acc;
            }
            if (!acc[item.assignee.name]) {
                acc[item.assignee.name] = new IssueGroup(item.assignee.username,
                    item.assignee.name)
            }
            acc[item.assignee.name].addIssue(item);
            return acc
        }, {});

        return {
            tags: tags,
            issues: newList,
            total: total,
            people: map
        };
    }

    fetchData(mode = '') {
        this.refs.reload_btn.disabled = true;
        const { match: { params: { milestone = 'CurrentMilestone', clientId } } } = this.props;

        this.setState({
            issueList: null,
            error: null,
            people: null,
            total: 0
        });

        const params = {
            mode: mode,
            milestone,
            clientId
        };
 
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps != this.props) {
            this.fetchData();
        }
    }

    onFilter(e) {
        this.setState({ filter: e.target.value });
    }

    onTagClick(name) {
        if (!this.state.tagStatus[name]) {
            this.state.tagStatus[name] = 1;
        } else {
            delete this.state.tagStatus[name];
        }
        this.forceUpdate();
    }
    render() {
        const { error, filter, tagStatus } = this.state;
        let message = null;
        const { total, people, issues, tags } = this.filteredIssues();
        if (issues && issues.length == 0) {
            message = "Empty List";
        }
        let burnDown = null, board = null;

        if (issues) {
            if (issues.length == 0) {
                burnDown = 'Empty List';
                board = 'Empty List';
            } else {
                burnDown = <BurnDown issueList={issues} />
                board = <IssueBoard issues={issues}
                    mode='project'
                    id={clientId}
                />
            }
        }


        const clientId = this.props.match.params.clientId;
        return <div >
            <div className="w3-theme w3-container">
                <h3>Client - {clientId}
                </h3>
            </div>
            
            <div>
                <div className="w3-col m7 s12 w3-padding-small">
                    {burnDown}
                </div>
                <div className="w3-col m5 s12 w3-padding-small">
                    {<TimePersonTable error={error} message={message}
                        people={people} total={total} />}
                    <div className="w3-margin-top">
                        <TagCloud tags={tags} tagStatus={tagStatus} parent={this} />
                    </div>
                </div>
            </div>
            <div className="w3-padding-small">
                <div className="w3-bar w3-margin-bottom">
                    <div className="w3-col m4 w3-right">
                        <input onChange={this.onFilter.bind(this)} className="w3-input w3-border w3-small " />
                    </div>
                </div>
                {board}
            </div>
        </div>
    }
}
export default (Client);