
import React, { useEffect, useState } from 'react';
import { formatSecs, getIssueProject, paginatedFetch, iAxios } from '../../lib/utils.js'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { alertMessage } from '../../lib/actions.js';
import { ModalManager } from '../common/Helper.js';


const RelatedBlock = ({ item }) => {
    return <div className="w3-blue-grey w3-round" style={{
        margin: "10px 4px 0px 0px",
        padding: "10px 6px"
    }}>
        <a className="w3-block w3-small"
            href={item.url}>{item.title} <br />
            <span>S: {formatSecs(item.timeSpent)}/E : {formatSecs(item.timeEstimate)}</span>
            <span className="w3-tag cgs w3-red -grey w3-round w3-small">{item.milestone ? item.milestone.title : 'NoMilestone'}</span>
        </a>
    </div>
}

export const IssueModal = () => {


    const ticketId = useSelector(st => st.modalStatus['issueModal'])
    const [data, setData] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!ticketId) {
            return;
        }
        iAxios.get(`/api/v1/tickets/detail/${ticketId}`).then(resp => {
            setData(resp.data.data);
        }).catch(ex => {
            dispatch(alertMessage(ex.message, 'Error', 'w3-red'));
        })
    }, [ticketId]);

    return <ModalManager modalName="issueModal" className="w3-light-grey">
        {data && <div  >
            <div className='w3-padding-16'>
                <h3>{data.ticket?.title}</h3>
                <div className='w3-padding-16'>{data?.ticket?.description}</div>
                <table className='w3-table w3-table-all'>
                    <thead>
                        <tr>
                            <th>Client</th>
                            <th>Project</th>
                            <th>Component</th>
                            <th>Assignee</th>
                            <th>Status</th>
                        </tr></thead>
                    <tbody>
                        <tr>
                            <td>{data?.ticket?.clientId}</td>
                            <td>{data?.ticket?.projectId}</td>
                            <td>{data?.ticket?.componentId}</td>
                            <td>{data?.ticket?.assignee.name}</td>
                            <td>{data?.ticket?.state}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className=' w3-padding-16'>
                <h4>User Activity</h4>
                {data?.logs?.map(i => <div className='w3-white w3-round w3-margin-bottom w3-padding'>
                    <div className=''>{i.data?.info}</div>
                    <div className='w3-small w3-right-align w3-text-grey'>
                        {i.userId}, {moment(i.createdAt).fromNow()}
                    </div>
                </div>)}
            </div>
        </div>}
    </ModalManager>
}

export const IssueBlock = (props) => {
    const { item, loadLinked, labelsToRemove, user } = props;
    const [linkedIssues, setLinked] = useState([]);
    const [deleted, setDeleted] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loadLinked) {
            return;
        }
        if (item.labels.indexOf("TaskSplit") === -1) {
            return;
        }
        const project = getIssueProject(item);
        paginatedFetch('/api/v1/tickets/related-issues', {
            project: project,
            iid: item.gitlabId
        }).then(resp => {
            const list = resp.data.map(item => {
                return <RelatedBlock key={item.id} item={item} />
            });
            setLinked(list);
        });
    }, []);


    const deleteItem = (id) => {
        iAxios.post('/api/v1/tickets/delete', {
            id
        }).then(result => {
            setDeleted(true)
        });
    }
    const handleClick = (e) => {
        if (e.target.href.indexOf('internal://') == -1) {
            return;
        }
        e.preventDefault();
        dispatch({
            type: 'show-modal',
            name: 'issueModal',
            data: item.ticketId
        })
    }

    const labels = item.labels.filter(l => labelsToRemove.indexOf(l) < 0);
    const project = getIssueProject(item);
    let bg = "#FFFFFF";
    let color = 'black';
    let overShoot = null;
    if (deleted) {
        return null;
    }
    if (item.timeEstimate === null) {
        bg = "#DEDEDE";
    } else if (item.timeEstimate < item.timeSpent) {

        let perc = (item.timeSpent / item.timeEstimate) - 1;
        if (perc > 0) {
            bg = `rgba(255,0,0,${perc / 2})`;
            color = "black";
            if (perc / 3 > 0.7) {
                color = 'white';
            }
        }
        overShoot = <span data-col={`${bg}`} > OverShot : {Math.round(perc * 100)}%</span>
    }

    const st = {
        color: color,
        background: bg,
    };

    if (item.state == 'closed') {
        st.borderTop = 'solid 4px #0ed60e';
    }

    if (item.timeEstimate > 16 * 60 * 60) {
        st.borderBottom = 'solid 4px #ce3100';
    }


    return <div className="cgs-issue-block w3-display-container" style={st} id={item.id}>
        {item.complexity ? <span className="w3-right w3-badge w3-blue-gray w3-small">{item.complexity}</span> : null}
        <a key={"issue_" + item.id} className="w3-block "
            target="_blank" rel="noreferrer"
            onClick={handleClick}
            href={item.url}>
            {item.title}
        </a>
        <br />
        <span>Spent : {formatSecs(item.timeSpent)} /  Estimate : {formatSecs(item.timeEstimate)}</span> <br />
        <span>Due : {item.dueDate ? moment(item.dueDate).format('MMM DD') : 'Not set'}</span> <br />

        {overShoot}<br />
        <span className="w3-tag cgs w3-teal w3-small "> {project} </span>
        {
            labels.map(p => <span key={p}
                className="w3-tag cgs w3-blue w3-small w3-round">{p}</span>)
        }
        {linkedIssues}
        {user?.role === 'Admin' && <button
            onClick={() => deleteItem(item.id)}
            className="w3-button w3-padding-small w3-round w3-display-hover w3-display-bottomright" style={{ margin: '5px' }}>delete</button>}
    </div>
}
