import { combineReducers, bindActionCreators } from "redux";
import moment from 'moment';
import { act } from "react";

function apps(state = { isFetching: false, apps: [] }, action) {
    switch (action.type) {
        case "REQUEST_APPS":
            return Object.assign({}, state, {
                isFetching: true
            });
        case "RECEIVE_APPS":
            return Object.assign({}, state, {
                isFetching: false,
                apps: action.apps
            });
        default:
            return state
    }
}

const milestoneList = (state = null, action) => {
    if (action.type === 'SET_MILESTONE_LIST') {
        action.milestoneList.sort((a, b) => moment(b.start) - moment(a.start))
        return action.milestoneList;
    }
    return state;
}


const works = (st = [], action) => {
    return st;
}

const userInfo = (st = null, action) => {
    if (action.type === 'SET_USER_INFO') {
        localStorage.setItem('user', JSON.stringify(Object.assign({
            fetchTime: Date.now()
        }, action.data)));
        return action.data;
    } else if (action.type === 'UPDATE_USER_INFO') {
        console.log('local user update');
        return action.data;
    }
    return st;
}

const appConfig = (st = { sections: [] }, action) => {
    if (action.type === 'SET_APP_CONF') {
        st = action.data;
    }
    return st;
}

const modalStatus = (st = { popupName: '' }, action) => {
    if (action.type === 'show-modal') {
        st = Object.assign({}, st, {
            [action.name]: action.data
        })
    } else if (action.type === 'hide-modal') {
        st = Object.assign({}, st, {
            [action.name]: null
        })
    } else if (action.type === 'popup') {
        st = Object.assign({}, st, {
            popupName: action.name
        })
    }
    return st;
}
const applyFilter = (list, userList, projectFilter) => {


    let result = list;
    if (!result) {
        return result;
    }

    result = result.filter(item => userList.some(u => u.username === item.assignee.username));
    if (projectFilter && projectFilter !== '') {
        // result = result.filter(item => item.projectId.indexOf(projectFilter) === 0 );
    }
    return result;
}

const tickets = (st = {
    ticketList: null,
    ticketListFiltered: null,
    userFilter: [],
    projectFilter: '',
    fetchFilter: { start: '', end: '' }
}, action) => {
    if (action.type === 'reload-tickets') {
        st = Object.assign({}, st, {
            ticketList: null,
            ticketListFiltered: null
        });
    } else if (action.type === 'set_issue_list') {
        st = Object.assign({}, st, {
            ticketList: action.list,
            ticketListFiltered: applyFilter(action.list, st.userFilter, st.projectFilter),
            fetchFilter: action.filter
        });
    } else if (action.type === 'filter_users') {
        console.log('Filter users', action)
        st = Object.assign({}, st, {
            ticketListFiltered: applyFilter(st.ticketList, action.users, st.projectFilter),
            userFilter: action.users
        });
    }
    return st;
}

const apiData = (st = {}, action) => {
    if (action.type && action.name && action.result) {
        st = Object.assign({}, st, {
            [action.name]: action.result
        });
    }

    return st;
}
export default combineReducers({
    userInfo,
    works,
    appConfig,
    apiData,
    modalStatus,
    tickets,
    apps,
    milestoneList
});