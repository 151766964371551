import { useEffect, useState } from "react";
import { iAxios } from "../../lib/utils";
import moment from "moment";
import { useSelector } from "react-redux";
import { StatusLabel } from "../common/Helper";

const TeamActivity = (props) => {
    const [data, setData] = useState([]);
    const [timer, setTimer] = useState(Date.now());
    const userFilter = useSelector(st => st.tickets.userFilter)

    useEffect(() => {
        iAxios.get('/api/v1/team-activity').then(resp => {
            const list = resp.data.data;
            setData(list.filter(t => userFilter.some(u => u.username === t.username)));
        });
    }, [timer, userFilter])

    useEffect(() => {
        const timerId = setInterval(() => {
            setTimer(Date.now());
        }, 5e4)
        return () => {
            clearInterval(timerId);
        }
    }, [1]);
    return <div className='w3-padding w3-center'>
        {data.map((item, pid) => {
            return <div key={item.name} className='w3-show-inline-block w3-black w3-center w3-round' style={{ margin: "4px" }}>
                <div style={{ position: 'relative' }}>
                    {(!item.screens || item?.screens.length === 0) && <div
                        className='no-screen-update w3-pale' >Not updated</div>
                    }

                    {item?.screens?.map((screen, idx) => {
                        if (screen.tm < Date.now() - 36e5) {
                            return <div key={`${idx}-${screen.tm}`} className='no-screen-update w3-left w3-pale-yellow' >
                                Last Updated : {moment(screen.tm).fromNow()}
                            </div>
                        }
                        return <div key={`screen-${pid}-${idx}`} className='w3-left'>
                            <img className="screenshot"  onError={(e)=> e.target.src='/missing.png'} src={screen.url} alt="screenshot " /><br />
                            {moment(screen.tm).fromNow()}
                        </div>
                    })}
                    {item.statusGroup !== 'In' && <div
                        style={{ position: 'absolute', right: '2px' }}>
                        <StatusLabel info={(item)} />
                    </div>}
                </div>
                <div className='w3-center'>
                    {<a href={`/my-day/${item.username}`}
                        style={{ textDecoration: 'none' }}
                        target='_blank' rel='noreferrer' >{item.name}</a>}
                </div>
            </div>
        })}
    </div>
}

export default TeamActivity;