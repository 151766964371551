import classNames from 'classnames';
import { iAxios, paginatedFetch } from './utils';

export const REQUEST_APPS = 'REQUEST_APPS'
export const RECEIVE_APPS = 'RECEIVE_APPS'



// const v2 = localStorage.getItem('auth_token') ;
// if (v2){
//   API.userInfo = '/api/users/me'
// }

export const actionClearStorage = () => {
  const keys = Object.keys(localStorage);
  for (const key of keys) {
    localStorage.removeItem(key);
  }
}

export const actionFetchAdminUsers =  () => (dispatch)=>  {
  dispatch({
    type:'apiData',
    name:'adminUsers',
    result: null
  });
  iAxios.get('/api/v1/admin/users').then(res=> {
    dispatch({
      type:'apiData',
      name:'adminUsers',
      result: res.data.data
    })
  })
}

export const alertMessage = (message, title = 'Success', cls = '') => {
  return {
    type: 'show-modal',
    name: 'alertModal',
    data: {
      title,
      message,
      msgCls: classNames({
        'w3-padding w3-round': true,
        [cls]: true
      })
    }
  }
}

export const fetchMilestones = () => (dispatch) => {
  return iAxios('/api/v1/tickets/milestones')
    .then(resp => {
      dispatch({
        type: 'SET_MILESTONE_LIST',
        milestoneList: resp.data.data
      });
      return resp;
    });
};

export const fetchUserInfo = (force = true) => (dispatch,getState) => {

  const {userInfo} = getState(); 

  if(!force && userInfo &&  (Date.now() - userInfo.fetchTime) < 59e3){
    console.log('Recent Fetchd and skipping')
    return userInfo;
  }

  return iAxios.get('/api/v1/auth/user').then(resp => {
    dispatch({
      type: 'SET_USER_INFO',
      data: resp.data.user
    });
    return resp;
  });
}


export const setMilestoneName = (name) => {
  return {
    type: 'SET_MILESTONE_NAME',
    milestoneName: name
  };
}



export const actionFetchIssues = (milestone, mode = 'normal') => (dispatch) => {
  dispatch({ type: 'reload-tickets' })
  paginatedFetch('/api/v1/tickets/list', {
    page: 1,
    mode: mode,
    start: milestone.start,
    end: milestone.end
  }).then(resp => { 
    dispatch({
      type: 'set_issue_list',
      list: resp.data,
      filter: {
        start: milestone.start,
        end: milestone.end
      }
    });
  }).catch(err => {
    console.error(err);
  });
}
