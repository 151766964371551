import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { formatSecs, getDiffClass } from '../../lib/utils';

const MemberStats = ({ progVal, tmTotal, prog, statsList, milestone /** Milestone Object */ }) => {

    const [sortType, setSortType] = useState('estimate');
    const [sortDir, setSortDir] = useState('asc');
    const memberStats = statsList.map(item => item.getStats(milestone));
    const [sp] = useSearchParams();

    memberStats?.sort((a, b) => {
        if (sortType == 'spent' && sortDir === 'asc') {
            return (a.vals.spent) - (b.vals.spent)
        }
        if (sortType === 'spent' && sortDir === 'desc') {
            return (b.vals.spent) - (a.vals.spent)
        }

        if (sortType === 'estimate' && sortDir === 'asc') {
            return (a.vals.estimate) - (b.vals.estimate)
        }
        if (sortType === 'estimate' && sortDir === 'desc') {
            return (b.vals.estimate) - (a.vals.estimate)
        }
        if (sortType === 'name' && sortDir === 'asc') {
            return a.name.localeCompare(b.name);
        }
        if (sortType === 'name' && sortDir === 'desc') {
            return b.name.localeCompare(a.name);
        }
        if (sortType === 'completion' && sortDir === 'asc') {
            return a.perc.completed - b.perc.completed
        }
        if (sortType === 'completion' && sortDir === 'desc') {
            return b.perc.completed - a.perc.completed;
        }

        return (a.vals.spent) - (b.vals.spent)

    });

    const toggleSort = (type) => {
        if (type == sortType) {
            setSortDir(sortDir == 'asc' ? 'desc' : 'asc');
        } else {
            setSortType(type);
            setSortDir('asc');
        }

    }
    const headers = [
        { name: 'User', key: 'name' },
        { name: 'Spent', key: 'spent' },
        { name: 'Estimate', key: 'estimate' },
        { name: 'Tasks', key: 'completion' },
    ];

    return (
        <div className=" w3-white ">
            <h4 className="w3-center w3-padding-small">Progress
                <span className="cgs-muted w3-tiny">
                    {prog > 0 && <span> {prog}% |  completed {formatSecs(progVal)}of {formatSecs(tmTotal)} Sprint Time</span>}
                </span>
            </h4>
            <table className="w3-table cgs-table w3-border-top">
                <thead>
                    <tr >
                        <th>#</th>
                        {headers.map(item => {
                            let icon = null;
                            if (item.key == sortType) {
                                icon = <i className={sortDir == 'asc' ? 'ion-ios-arrow-up' : 'ion-ios-arrow-down'} style={{
                                    marginLeft: "4px",
                                    position: 'relative',
                                    top: '2px'
                                }} />
                            }
                            return <th key={item.key} onClick={e => toggleSort(item.key)}>{item.name}{icon}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {memberStats.length === 0 && <tr><td className='w3-center w3-padding-64' colSpan={1 + headers.length}>
                        Please add tasks and update completion status to show information here
                    </td></tr>}
                    {memberStats.map((item, idx) => {
                        const progDiff = progVal - item.vals.spent;
                        const cls = getDiffClass(progDiff, [3600 * 16, 3600 * 12, 3600 * 8]);
                        const progCls = getDiffClass(prog - item.perc.completed);
                        const estCls = getDiffClass(item.tmTotal - item.vals.estimate, [3600 * 24, 3600 * 16, 3600 * 8]);
                        return <tr key={`row_${idx}`} className={cls} data-id={`${progDiff / 3600}`}>
                            <td>{idx + 1}</td>
                            <td>
                                <Link to={`/member/${item.uname}/?${sp.toString()}`}> {item.name}
                                    {item.vals.toPlan !== 0 && <span className="cgs-muted w3-right w3-red cgs-small-tag"
                                        title="issues without estimate"> {item.vals.toPlan}</span>}
                                </Link>
                            </td>
                            <td title={`${progDiff / 3600} hours not marked`}>
                                {progVal !== 0 && <span title="percentage of elapsed time logged"
                                    className="w3-tiny cgs-muted  w3-right">
                                    {(item.vals.spent * 100 / progVal).toPrecision(3)}%</span>}
                                <span style={{ display: "inline-block" }}>{formatSecs(item.vals.spent)}</span>
                            </td>
                            <td className={estCls}>
                                <span title={`Non Completed estimate`} className="cgs-muted w3-display-topright w3-aqua">
                                    {formatSecs(item.vals.estimate - item.vals.closedEst, 'hour')}
                                </span>
                                <span title={`Total Estimate Percentage`} className="cgs-muted w3-display-bottomright w3-purple">
                                    {(item.vals.estimate * 100 / tmTotal).toPrecision(3)}%
                                </span>
                                <span title="Total Estimate">{formatSecs(item.vals.estimate)} </span>

                            </td>
                            <td className={progCls}>
                                <span className="cgs-muted w3-right" title="self closed / closed / total" >[ {item.vals.selfClosed} / {item.vals.completed} / {item.count} ]</span>
                                <span title="Task completion percentage" style={{ display: "inline-block" }}>{item.perc.completed}% </span>
                            </td>
                        </tr>
                    })
                    }</tbody>
            </table>
        </div>
    );
}


export default MemberStats;