
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { iAxios, getDiffClass } from '../../../lib/utils';
import moment from 'moment';

import ReactMarkdown from 'react-markdown'
import { useDispatch } from 'react-redux';
import { useMilestone, useTeamList } from '../../../lib/hooks';
import { Spinner, StatusLabel } from '../../common/Helper';

const Names = ({ list, max = 2 }) => {
    const items = [];
    while (items.length < max && list.length > 0) {
        items.push(list.shift());
    }
    if (list.length > 0) {
        items.push(`+${list.length} more`);
    }
    return <div className="w3-right cgs-capitalize" style={{ marginRight: '8px' }}> {items.join(', ')} </div>
}

const Reportees = ({ data, leadEmail, msgList }) => {
 
    return <ol className={leadEmail === null ? "team-start" : "team-cont"}>
        {data.filter(item => item.teamLead === leadEmail)
            .map(item => {
                let msg = msgList.find(msg => msg.username === item.username);
             
                let infoCls = 'w3-text-orange'; 
                 
                if (!msg && !item.memberStats) {
                    infoCls = 'w3-text-red w3-large';
                    msg = { msg: "*Need to add tickets for the sprint*" }
                }
               
                const hasRep = data.some(row => row.teamLead === item.email);
                return (<li key={item.username}>
                    <span className={item.memberStats ? '' : ' w3-text-red bold-text'}>
                        <Link className="cgs-plain-link " to={`/member/${item.username}`}>{item.name}
                            {item.data?.status && <StatusLabel info={item.data} />}
                        </Link>
                         

                        {msg?.msg && <span className="w3-dropdown-hover">
                            <i className={`ion w3-large ion-alert-circled ${infoCls} w3-margin-left`} />
                            <span className="w3-dropdown-content w3-padding-small w3-black">
                                <div>{msg.msg} </div>
                            </span>
                        </span>}
                         
                    </span>
                    <Reportees data={data} leadEmail={item.email} msgList={msgList} />
                </li>)
            })}
    </ol>
}

const Leads = (props) => {
    const [data, setData] = useState([]);

    const [msgList, setMsgList] = useState([]);
    const milestone = useMilestone();
    const userList = useTeamList();

    useEffect(() => {
        if (!milestone) {
            return;
        }
        setData(null);
        iAxios.get(`/api/v1/tickets/team-status/`, {
            params: {
                start: milestone.start,
                end: milestone.end
            }
        }).then(res => {
            setMsgList(res.data.data);
        })
    }, [milestone, props.userList]);


    useEffect(() => {
        const nData = userList.map(item => {
            const group = props.statsList.find(u => u.uname === item.username);
            return Object.assign(item, { memberStats: group ? group.getStats(milestone) : null });
        }); 
        setData(nData);
    }, [props.statsList, userList,milestone])


    return <div className=" w3-white w3-margin-bottom" style={{ minWidth: "320px" }}>
        <h4 className="w3-center w3-padding w3-border-bottom " >Team</h4>
        {!data && <Spinner />}
        {data && <Reportees data={data} leadEmail={null} msgList={msgList} />}
    </div>
}

export default Leads;