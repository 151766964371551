import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { iAxios } from "../../../lib/utils";
import { alertMessage } from "../../../lib/actions";
import { GenericForm } from "../../common/GenericForm";
import { ZohoConnect } from "./ZohoConnect";
import classNames from "classnames";
import moment from "moment";


const JiraConnectConfig = [{
    name: 'server',
    label: 'Jira Server URL'
}, {
    name: 'email',
    label: 'User Email Id',
}, {
    name: 'token',
    label: 'Auth Token Generated from Settings'
}]

const JiraCard = ({ item, loadData }) => {

    const dispatch = useDispatch();
    const [search, setSearch] = useState();
    const [userList, setUserList] = useState([]);

    const handleStatusChange = (e) => {
        iAxios.post('/api/v1/admin/update-source-status', {
            sourceId: item.id,
            status: e.target.value
        }).then(resp => { 
            if (!resp.data.success) {
                dispatch(alertMessage(`Unable to update status of ${item.name}`))
                return
            }
            dispatch(alertMessage(`Status of ${item.name} updated to ${resp.data.data.status}`))
            loadData()
        })
    }
    const createMapping = (e) => {
        e.preventDefault();
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }
        iAxios.post('/api/v1/admin/jira-create-user-mapping', data).then(resp => {
            console.log(resp);
            setUserList([]);
        }).catch(ex=>{
            alert(ex.message)
        })
        console.log(data);
    }

    const handleFetchUsers = () => {
        iAxios.get('/api/v1/admin/jira-fetch-users', {
            params: {
                sourceId: item.id,
                search
            }
        }).then(res => {
            setUserList(res.data.data);
        })
    }

    let sycedTime = 'Never';
    if (moment(item.syncedAt).isAfter('2010-01-01')) {
        sycedTime = moment(item.syncedAt).fromNow();
    }

    return <div className={classNames("w3-padding   w3-round w3-margin-bottom", {
        'w3-light-grey': item.status === 'Active',
        'w3-pale-red': item.status === 'Inactive',
    })}>
        <div className=" w3-row ">
            <div className="w3-col m9 s9">
                <h4>{item.name}</h4>
            </div>
            <div className="w3-col w3-hide m3 s3 w3-right-align ">
                <button className="w3-button w3-padding-small  w3-round w3-theme-l1">Edit</button>
            </div>
            <div className="w3-col m9 s8 ">
                Server : {item.server}<br />
                Project: {item.team} <br />
                Last Synced At : {sycedTime}
            </div>
            <div className="w3-col m3 s4 ">
                <select onChange={handleStatusChange} className="w3-input w3-round w3-margin-top" defaultValue={item.status}>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                </select>
            </div>
            {item.status === 'Active' && <div className="w3-col m12 w3-margin-top">
                {userList.length === 0 && <>
                    <div className="w3-col m9">
                        <input className="w3-input" type="text" onChange={e => setSearch(e.target.value)} />
                    </div>
                    <div className="w3-col m3">
                        <button className="w3-button w3-black" onClick={handleFetchUsers}>Search Users</button>
                    </div></>}

                {userList.length > 0 && <form className="w3-padding-16" onSubmit={createMapping}>
                    {userList.map((item, idx) => {
                        return <div className="w3-col m12 w3-padding-16" key={item.accountId}>
                            <div className="w3-col m2">{idx + 1}</div>
                            <div className="w3-col m5 w3-left-align">{item.displayName}</div>
                            <div className="w3-col m5 "><input className="w3-input" placeholder="Enter email id to map" name={`${item.accountId}`} /></div>
                        </div>
                    })}
                    <div className="w3-col m12 w3-padding-16">
                        <div className="w3-col m6">
                            <button className="w3-button w3-round w3-padding-small w3-black" onClick={e => setUserList([])}>Cancel</button>
                        </div>
                        <div className="w3-col m6 w3-right-align">
                            <button className="w3-button w3-round w3-padding-small w3-blue">Submit</button>
                        </div>
                    </div>
                </form>}

            </div>}
        </div>
    </div>

}

const InfoBody = ({ data }) => {
    return <div>
        {data.newList.length > 0 && <div>New
            <ul className="w3-list">
                {data.newList.map(item => <li>{item.name} from {item.server}</li>)}
            </ul>
        </div>
        }
        {data.existing.length > 0 && <div>Existing
            <ul className="w3-list">
                {data.existing.map(item => <li>{item.name} from {item.server}</li>)}
            </ul>
        </div>}
    </div>
}

const JiraConnect = ({ sources, loadData }) => {
    const [projects, setProjects] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [syncData, setSyncData] = useState(null);
    const userInfo = useSelector(st => st.userInfo);
    const dispatch = useDispatch();
    const connectJira = (data) => {
        iAxios.post('/api/v1/admin/jira-connect', data).then(resp => {
            console.log(resp.data);
            setProjects(resp.data);
            setSyncData(data);
        })
    }
    const onSelectionChange = (e) => {
        if (e.target.checked) {
            setSelectedProjects([e.target.value, ...selectedProjects]);
        } else {
            setSelectedProjects(selectedProjects.filter(item => item !== e.target.value))
        }
    }

    const connectProjects = (e) => {
        e.preventDefault();

        iAxios.post('/api/v1/admin/jira-connect-projects', {
            authData: syncData,
            projectList: projects,
            selectedProjects
        }).then(resp => {
            console.log(resp.data);

            dispatch(alertMessage(<InfoBody data={resp.data} />, 'Synced Projects Successfully'))
        })
    }

    return <div>
        <h4>Jira Integration</h4>
        <div className="w3-large w3-margin-bottom">
            Integrate your  projects, sprints and tickets from Jira
        </div>
        <div >
            {projects.length === 0 && <div className="w3-col m8 w3-padding w3-margin-bottom">
                <GenericForm fields={JiraConnectConfig} 
                    onSubmit={connectJira}
                    initVals={{
                        emailId: userInfo?.email
                    }}
                    submitLabel="Connect Jira "
                    formClass='w3-row-padding w3-padding-16'
                    submitOptions={{ className: 'w3-button w3-round w3-border   w3-theme-d2 w3-right' }}
                    fieldClass='w3-input w3-border  w3-margin-bottom w3-round' />
                <a href="https://id.atlassian.com/manage-profile/security/api-tokens" target="_blank" rel="noreferrer">Create token from here</a>
            </div>
            }
            {projects.length > 0 &&
                <form className="w3-margin-16 w3-light-grey" onSubmit={connectProjects}>
                    <div className="w3-padding w3-border-bottom">
                        <h3>Select Projects to Sync</h3>
                    </div>
                    {projects.map((item, idx) => {
                        return <div key={item.key} className=" w3-margin-bottom w3-padding w3-large w3-border-bottom">
                            <label>
                                {idx + 1}. <input type="checkbox" onChange={onSelectionChange} value={item.key} checked={selectedProjects.includes(item.key)} className="w3-check w3-right" />
                                {item.name}<br />
                                Key: {item.key}<br />
                                Id: {item.id}<br />
                            </label>
                        </div>
                    })}
                    <div className="w3-row w3-right-align w3-padding">
                        <div className="w3-col m6 w3-left-align">
                            <button className="w3-button w3-black w3-round " onClick={e => setProjects([])}>Cancel</button></div>
                        <div className="w3-col m6">
                            <button className="w3-button w3-theme-l1 w3-round ">Sync Projects</button>
                        </div>
                    </div>
                </form>
            }
            <div className="w3-padding-16">
                {sources.filter(item => item.type === 'jira').map(item => <JiraCard item={item} loadData={loadData} />)}
            </div>
        </div>
    </div>
};

export const Integrations = (props) => {
    const [integrations, setIntegrations] = useState([]);
    const [sources, setSources] = useState([]);
    const dispatch = useDispatch();


    const loadData = useCallback(() => {
        iAxios.get('/api/v1/admin/integrations').then(resp => {
            setIntegrations(resp.data.data);
        }).catch(ex => {
            dispatch(alertMessage(ex.message))
        })
        iAxios.get('/api/v1/admin/sources').then(resp => {
            setSources(resp.data.data.sort((a, b) => a.status.localeCompare(b.status)));
        }).catch(ex => {
            dispatch(alertMessage(ex.message))
        })
    }, [1])

    useEffect(() => {
        loadData()
    }, [1])


    return <div className="w3-margin-top w3-row">
        <ZohoConnect integrations={integrations} loadData={loadData} />
        <div className=" w3-col m7 w3-padding w3-white w3-margin-bottom">
            <h4>Integrate  Open AI</h4>
            <div className="w3-large w3-margin-bottom">
                Add your open AI Key Token to
            </div>
            <input className="w3-input w3-border w3-margin-bottom" type="text"
                name="openAIKey" placeholder="Open AI Key"></input>
            <button className="w3-button w3-theme-d1 w3-round w3-margin-bottom w3-right" href="/auth/zoho">Save API Key</button>
        </div>


        <div className=" w3-col m7 w3-padding w3-white w3-margin-bottom w3-hide" >
            <h4>Integrate  gitlab</h4>
            <div className="w3-large w3-margin-bottom">
                Integrate your employee details,  projects and tickets  with gitlab
            </div>
            <input className="w3-input w3-border w3-margin-bottom" type="text"
                name="gitlabServer" placeholder="Gitlab Server Url"></input>

            <input className="w3-input w3-border w3-margin-bottom" type="text"
                name="gitlabAccessToken" placeholder="Gitlab Personal Access Token"></input>

            <input className="w3-input w3-border w3-margin-bottom" type="text"
                name="gitlabServiceId" placeholder="Gitlab Service Account Id"></input>

            <input className="w3-input w3-border w3-margin-bottom" type="text"
                name="gitlabServiceToken" placeholder="Gitlab Service Account Token"></input>
            <div>
            </div>
            <a className="w3-button w3-theme-d1 w3-round w3-margin-bottom w3-right" href="/auth/zoho">Connect Gitlab</a>
        </div>
        <div className=" w3-col m7 w3-padding w3-white">
            <JiraConnect sources={sources} loadData={loadData} />
        </div>

    </div >

}