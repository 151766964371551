import { useEffect, useState } from "react"
import { formatSecs, iAxios, getActivityCls } from "../../../lib/utils"
import { Spinner, TabFilter } from "../../common/Helper";
import { useMilestone } from "../../../lib/hooks";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment';
import classNames from "classnames";

const TimeLabel = (props) => {
    let { label, cls, secs, count, username, date = '' } = props;
    if (!cls) {
        cls = label.toLowerCase();
    }
    let expected = 3600 * 8;
    if (cls === 'break') {
        expected = 3600 * 1;
    }
    let perc = Math.floor((secs / expected) * 100);
    const color = getActivityCls(cls, secs);
    // let prCls = 'w3-blue'
    // if (color == 'cls-good' < 60) {
    //     prCls = 'w3-red'
    // } 
    return <a target="_blank" rel="noreferrer" title={`Total ${count}`} href={`/my-day/${username}?date=${date}`}
        className={`action-tm  ${color} w3-round  `} style={{ textDecoration: 'none' }}>
        <div className="w3-white w3-round  action-prog" title={`${perc} %`}>
            <div className={` w3-blue w3-round action-prog-line`} style={{ width: `${perc > 100 ? 100 : perc}%` }}></div>
        </div>
        {label} : {formatSecs(secs)}
    </a>

}
const InOutDashboard = () => {
    const [statsData, setStatsData] = useState(null);
    const [dateList, setDateList] = useState([]);
    const [classList,setClassList] = useState(['show-good','show-bad','show-flagged'])
    const [tableData, setTableData] = useState([]);
    const [modes, setModes] = useState(['name', 'break-asc',
        'break-desc', 'in-asc', 'in-desc'])
    const userFilter = useSelector(st => st.tickets.userFilter)
    const milestone = useMilestone();


    useEffect(() => {
        if (!statsData) {
            return;
        }
        const empList = statsData.reduce((acc, item) => {
            if (!acc.includes(item.userId)) {
                acc.push(item.userId)
            }
            return acc;
        }, []);
        empList.sort()
        const dl = statsData.reduce((acc, item) => {
            if (!acc.includes(item.dt)) {
                acc.push(item.dt)
            }
            return acc;
        }, []);
        dl.sort((a,b) => b.localeCompare(a))
        setDateList(dl);
        const data = empList.filter(emp => {
            return userFilter.some(item => item.username === emp)
        }).map(emp => {
            let prevBrkDate = '';
            let prevInDate = '';
            return dl.reduce((acc, dt) => {
                const key = `${dt}-${acc.name}-${acc.content.length}`
                const list = statsData.filter(item => item.userId === emp && item.dt === dt);
                const info = list.map(el => {
                    const secs = parseInt(`${el.actionDuration}`) / 1000;

                    if (el.actionGroup === 'In') {
                        acc.totalIn += secs;
                        if (dt !== prevInDate) {
                            prevInDate = dt;
                            acc.totalCount++;
                        }
                    } else if (el.actionGroup === 'Break') {
                        acc.totalBreak += secs;
                        if (dt !== prevBrkDate) {
                            prevBrkDate = dt;
                            acc.brkCount++;
                        }
                    }
                    return <TimeLabel key={`${key}-${el.actionGroup}`} count={el.actionCount}
                        username={emp} date={dt} label={el.actionGroup} secs={secs} />
                })
                acc.content.push(<td id={key} key={key}>{info}</td>);
                return acc;
            }, { name: emp, totalBreak: 0, totalIn: 0, content: [], brkCount: 0, totalCount: 0 });
        });
        setTableData(data);
    }, [statsData, userFilter])

    useEffect(() => {
        if (!milestone) {
            return;
        }
        setStatsData(null);
        iAxios.get('/api/v1/admin/user-log-stats', {
            params: {
                start: milestone.start,
                end: milestone.end
            }
        }).then(resp => {
            setStatsData(resp.data.data.stats)
        }).catch(ex => {
            console.error(ex.message);
        });
    }, [milestone])

    const changeSort = () => {
        const [first, ...rest] = modes;
        const next = rest[0];
        console.log(`Sort :${next}`)
        tableData.sort((a, b) => {
            if (next === 'break-asc') {
                return (a.totalBreak / a.brkCount) - (b.totalBreak / b.brkCount);
            } else if (next === 'break-desc') {
                return (b.totalBreak / b.brkCount) - (a.totalBreak / a.brkCount);
            } else if (next === 'in-asc') {
                return (a.totalIn / a.totalCount) - (b.totalIn / b.totalCount);
            } else if (next === 'in-desc') {
                return (b.totalIn / a.totalCount) - (a.totalIn / b.totalCount);
            }
            return a.name.localeCompare(b.name);
        })
        setTableData([...tableData])
        setModes([...rest, first])
    }

    const toggleClass = (e) => {
        const val = e.target.value;
        if(classList.includes(val)){
            setClassList(classList.filter(i => i !== val))
        } else {
            setClassList([...classList,val])

        }
    }
    return <div className="w3-white w3-padding">
        {statsData == null && <Spinner />}
        {statsData && <div>
            <div className="w3-right-align w3-padding-16">
                <label className="w3-margin-left"><input value='show-good' checked={classList.includes('show-good')} onClick={toggleClass} className="w3-check " type="checkbox"/> Good</label>
                <label className="w3-margin-left"><input value='show-bad' checked={classList.includes('show-bad')}  onClick={toggleClass} className="w3-check " type="checkbox"/>Average</label>
                <label className="w3-margin-left"><input value='show-flagged' checked={classList.includes('show-flagged')} onClick={toggleClass} className="w3-check " type="checkbox"/>Flagged</label>
            </div>
            <table className={classNames("w3-table w3-table-all admin-table  scroll-table",classList ) }>
                <thead>
                    <tr><th className="sticky-col first-col"
                        onClick={changeSort} style={{ cursor: 'pointer' }} title={`Current Sort ${modes[0]}`}>
                        Name </th>

                        {dateList.map(item => <th key={item}
                        // style={{transform:'rotate(-90deg)'}}
                        >{moment(item).format('ddd, DD-MMM')}</th>)}
                    </tr>
                </thead>
                <tbody >
                    {tableData.map((info, idx) => {
                        return <tr key={`${info.name}-${idx}`}>
                            <td className="sticky-col first-col">{idx + 1}.{info.name}
                                <br />
                                {info.totalCount > 0 && <TimeLabel label="Avg In" cls='in' username={info.name}
                                    count={info.totalCount} secs={info.totalIn / info.totalCount} />}
                                {info.brkCount > 0 && <TimeLabel label="Avg Break" cls='break' username={info.name}
                                    count={info.brkCount} secs={info.totalBreak / info.brkCount} />}
                            </td>
                            {info.content}
                        </tr>
                    })}
                </tbody>
            </table>
        </div>}
    </div>
}

export const AdminDashboard = () => {

    const [sp] = useSearchParams();
    const tab = sp.get('tab') || 'inout';

    return <div>
        <TabFilter filters={['InOut', 'Insights']} name='tab' />
        <div className="w3-margin-top scrollable">
            {tab === 'inout' && <InOutDashboard />}
            {tab !== 'inout' && <div className="w3-padding-64 w3-center">These dashboards will populate once there is enough data.</div>}
        </div>
    </div>
}