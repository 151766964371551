import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { iAxios } from "../../../lib/utils";
import { alertMessage } from "../../../lib/actions";
import { GenericForm } from "../../common/GenericForm";

const ZohoFormConfig = [{
    name: 'zohoClientId',
    label: 'Zoho Client Id',
}, {
    name: 'zohoClientSecret',
    label: 'Zoho Client Secret',

}, {
    name: 'zohoCode',
    label: 'Zoho Code',
}, {
    name: 'zohoServer',
    label: 'Mult DC Accounts Server',
}]

export const ZohoConnect = ({ integrations, loadData }) => {

    const dispatch = useDispatch();
    const [mode, setMode] = useState('connected');
    const [zohoConnection, setZohoConnection] = useState(null);

    useEffect(() => {
        const con = integrations.find(item => item.refId === 'zoho-admin-integration' || item.name === 'zoho-integration');
        setZohoConnection(con);
    }, [integrations])

    const syncEmployees = () => {
        iAxios.post('/api/v1/admin/zoho-sync-employees').then(resp => {
            dispatch(alertMessage(`${resp.data.data.length} employee data synced successfully`));

        }).catch(ex => {
            alertMessage('Error while syncing emplyee data.Please try after sometime', 'Zoho Sync Error', 'w3-red');
        });
    }


    const syncLeaves = () => {
        iAxios.get('/api/v1/admin/zoho-sync-leaves').then(resp => {
            const { data, created } = resp.data;
            dispatch(alertMessage(`Employee leaves records synced : ${data.length} , New records created: ${created.length}`));
        }).catch(ex => {
            alertMessage('Error while syncing emplyee data.Please try after sometime', 'Zoho Sync Error', 'w3-red');
        });
    }

    const handleZohoAdminConnect = (data) => {
        iAxios.post('/api/v1/admin/zoho-connect', data).then(resp => {
            const data = resp.data;
            if (!data.success) {
                dispatch(alertMessage(data.message, 'Zoho Connect Error', 'w3-red'));
                return;
            }
            dispatch(alertMessage('Successfull Connected', 'Zoho Connect', 'w3-green'));
            loadData();
        });
    }

    return <div className=" w3-col m7 w3-padding w3-white w3-margin-bottom">
        <h3>Integrate Zoho People</h3>
        <div className="w3-large w3-margin-bottom">
            Integrate your employee details, team structure , attendence management with zoho
        </div>
        <div className="w3-row w3-margin-bottom">
            <div className="w3-col m12">

                {zohoConnection && <button className="w3-button w3-blue w3-round w3-padding-small w3-margin-right " onClick={syncEmployees}>Fetch Employee Data</button>}
                {zohoConnection && <button className="w3-button  w3-blue w3-round w3-padding-small w3-margin-right "
                    onClick={syncLeaves}>Sync Leave Data</button>}
                {mode != 'open' && <button type="button" onClick={e => setMode(mode === 'open' ? 'done' : 'open')}
                    className="w3-button w3-theme-d1 w3-round w3-padding w3-right ">
                    Reconnect Zoho
                </button>}

            </div>
        </div>
        {mode === 'open' && <div>
            <div className="w3-row-padding">
                <div className="w3-col m12">
                    For zoho integration preform following steps.
                    <ol>
                        <li>Goto Zoho  <a target="_blank" rel="noreferrer" href="https://api-console.zoho.com/">API Console</a></li>
                        <li>Create a self client </li>
                        <li>Copy paste the following scope in the Scope field</li>

                        <li>Select Duration as  10 min</li>
                        <li>Once Code is generated Copy paste Code, Client Id, Client Secret in the following form</li>
                        <li>Look at the address bar url of your API Console. Replace the "api-console" part of url with "accounts" to get the Multi DC Account Server </li>
                    </ol>
                    <textarea className="w3-input w3-textarea w3-border w3-round" rows="4" style={{ maxWidth: "100%", minWidth: '100%', maxHeight: '80px', minHeight: '80px' }} disabled>ZOHOPEOPLE.attendance.ALL,ZOHOPEOPLE.leave.ALL,ZOHOPEOPLE.dashboard.ALL,ZOHOPEOPLE.employee.ALL,ZOHOPEOPLE.forms.ALL,aaaserver.profile.READ
                    </textarea>
                </div>
            </div>

            <GenericForm fields={ZohoFormConfig}
                wrapClass='w3-col m8'
                onSubmit={handleZohoAdminConnect}
                onCancel={e => setMode('connected')}
                initVals={{
                    zohoServer: 'https://accounts.zoho.com',
                    zohoClientId: zohoConnection ? zohoConnection.data.input.zohoClientId : '',
                    zohoClientSecret: zohoConnection ? zohoConnection.data.input.zohoClientSecret : ''
                }}
                submitLabel="Connect Zoho "
                formClass='w3-row-padding w3-margin-top'
                submitOptions={{ className: 'w3-button w3-round w3-border w3-padding-small w3-theme-d2 w3-right' }}
                fieldClass='w3-input w3-border  w3-margin-bottom w3-round' >

            </GenericForm>

            {/* <a className="w3-button w3-theme-d1 w3-round w3-margin-bottom w3-right" href="/auth/zoho">Connect Zoho Account</a> */}
        </div>}
    </div>

}
