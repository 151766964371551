import React, { Component, useEffect, useState } from 'react'
import moment from 'moment';
import { Spinner, TabFilter } from '../common/Helper';
import { useParams, useSearchParams } from 'react-router-dom'
import { formatSecs } from '../../lib/utils'
import IssueGroup, { IssueGroupView } from '../perf/IssueGroup';
import { useSelector } from 'react-redux';
import { useConf, useUserTicketGrouper } from '../../lib/hooks';
const Member = () => {

    const [sp] = useSearchParams();
    let { username } = useParams();
    const userInfo = useSelector(st => st.userInfo);
    if (!username) {
        username = userInfo?.username;
    }

    const { groupedList } = useUserTicketGrouper(username);

    let filter = sp.get('filter');
    if (!filter) {
        filter = 'all'
    }

    let count = 0;
    let content = null;
    let totalEstimate = 0;
    let totalSpent = 0;

    if (groupedList == null || !username) {
        content = <div className="w3-center"><Spinner /></div>;
    } else {
        content = groupedList.reduce((acc, item) => {
            item.filter(filter);
            const view = <IssueGroupView group={item} filter={filter} />
            acc.push(view);
            return acc;
        }, []);
        // count = groupedList.reduce((acc, item) => acc + item.filtered, 0);
        if (content.length === 0) {
            content = <div className="w3-center w3-light-grey w3-padding-64"><h6>Empty List</h6></div>
        } else {
            content = <div className="w3-responsive">
                <div className="cgs-table" style={{ minHeight: "100vh" }}>
                    {content}
                </div>
            </div>
        }
    }

    if (groupedList) {
        totalEstimate = groupedList.reduce((acc, i) => acc + i.totalEstimate, 0);
        totalSpent = groupedList.reduce((acc, i) => acc + i.totalSpent, 0);
    }

    const spanItem = <span className="w3-white w3-round w3-maring-left w3-display-inline-block"
        style={{
            marginLeft: "10px",
            padding: "10px 20px"
        }}
    >{`${formatSecs(totalSpent)} / ${formatSecs(totalEstimate)}`}</span>

    const { workflow } = useConf();
    const filters = ['All', 'Pending', ...workflow, 'Spill']
    return (
        <div >
            <div className='w3-row'>
                <div className='w3-left'><h2>{username} </h2>
                </div>
                <div className='w3-left w3-padding-16 w3-margin-left'>{spanItem}
                </div>
            </div>

            <TabFilter
                filters={filters}
                name='filter'
                count={count}
            />

            <div className="w3-row w3-margin-top">
                {content}
            </div>
        </div >
    )

}

export default Member;
