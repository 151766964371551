import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { iAxios } from '../../lib/utils';
import { Footer, LurkerHeader } from '../common/Helper';
import { useConf } from '../../lib/hooks';


const Login = (props) => {

    const [mode, setMode] = useState('initial');
    const [error, setError] = useState(null);
    const { provider } = useParams()
    const{loginButtons} = useConf();
    let [sp,] = useSearchParams()
    const navigate = useNavigate();

    useEffect(() => { 
        const code = sp.get('code')
        if (code) {
            iAxios({
                method: 'post',
                url: `/api/v1/auth/${provider}`,
                data: sp
            }).then(resp => {
                const { data } = resp.data;
                localStorage.setItem('access_token', data.jwt);
                if(data.nextUrl) {
                    navigate(data.nextUrl);
                } else {
                    navigate('/')
                }


            }).catch(ex => {
                console.error(ex);
                if (ex.response?.data?.message) {
                    setError(ex.response.data.message)
                } else {
                    setError(ex.message);
                }
            })
        } 
    }, [sp, provider])

    return (
        <div>
            <div className="w3-white login-bg" >
                <LurkerHeader />
                {mode === 'progress' && <span>Logging you in..</span>}
                {mode === 'initial' &&
                    <div className='w3-row  w3-content w3-padding-64'>
                        <div className='w3-col m8  w3-padding   '>
                            <div className="bg-transparent w3-padding w3-round w3-large" >
                                <h2>Scale your teams productivity to new heights</h2>
                                <div>
                                In today’s digital world, managing screen time effectively has become crucial for maintaining productivity and focus. Zcalo is a cutting-edge application designed to monitor your screen usage and provide actionable insights to help you work smarter, not harder. Whether you’re an individual striving for better time management or an enterprise aiming to enhance team productivity, Zcalo is here to empower you with AI-driven recommendations tailored to your unique workflow.


                            </div>
                            </div>

                            <div className='info-alternate'>
                                    <div className='bg-transparent w3-padding w3-margin-top w3-large w3-round'>
                                        <h4>Step 1: Sign Up and Get Started</h4>
                                    Create an account using your company email. If your employer has already setup the account, use any of the auth providers for login .
                                    </div>
                                    <div className='bg-transparent w3-padding w3-margin-top w3-large w3-round'>
                                        <h4>Step 2: Install the Copilot Application</h4>
                                        Once you have logged in goto the downloads section to install the application for your operating system. The Copilot acts as your personal productivity assistant, working in the background to track your screen usage across apps, websites and other activities..
                                    </div>
                                    <div className='bg-transparent w3-padding w3-margin-top w3-large w3-round'>
                                        <h4> Step 3: Kickstart the Copilot from Your Web Dashboard</h4>
                                        The dashboard provides an overview of your prodicive time , Once you have logged in and given the location permission, you can login to the copilot application.
                                    </div>

                                    <div className='bg-transparent w3-padding w3-margin-top w3-large w3-round'>
                                   <h4> Step 4: Leverage AI-Driven Recommendations</h4>
                                    Zcalo’s AI engine analyzes your screen time data and identifies patterns, bottlenecks, and areas for improvement. Based on this analysis, it generates personalized recommendations to optimize your work. Whether it’s suggesting focused work sessions or blocking distractions during critical hour, Zcalo ensures that you stay on track and achieve your goals efficiently.
                                        </div>
                                     
                                </div>
                        </div>
                        <div className='w3-col m4  w3-padding w3-center'>
                            <div className='bg-transparent w3-round w3-round  w3-padding-32'  >
                                <div>
                                    <img src="/logo128.png" className='w3-image' alt="logo" />
                                    <h4>ZCALO Dashboard</h4>
                                </div>
                                {error && <div className='w3-padding w3-red w3-margin-bottom'>{error}</div>}
                                <div style={{padding:'20px 20px'}}>
                                {loginButtons.map(item => {
                                    return (<a  key={item.value} className={`w3-button w3-round w3-black w3-block w3-margin-bottom btn-${item.value} `} href={`/auth/${item.value}`}>{item.name}</a>)
                                })}
                                </div> 
                            </div>
                        </div>
                    </div>}
            </div>

            <Footer />
        </div>
    );

}
export default Login