import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import {  COLORS,iAxios } from '../../../lib/utils';
 
const ProductBacklog = ({ ASSIGNMENTS }) => {
    const [projectStatus,setProjectStatus] = useState([]);

    useEffect(()=> {
        iAxios.get(`/api/v1/tickets/open-status`).then((resp) => {
            const chartData = resp.data.data.filter(p => p.open).map((p) => {
                return {
                    name: p.name, 
                    value: parseInt(`${p.open ? p.open : 0}`) 
                        + parseInt(`${p.testing ? p.testing : 0}`) 
                        + parseInt(`${p.working ? p.working : 0}`)
                    
                }
            });
            chartData.sort((a, b) => b.value - a.value);
            setProjectStatus(chartData);
        }).catch((error) => {
            console.error('Error : ', error);
        });
        
    },[1])
    let total = 0;

    return <div className=" w3-white">
        <h4 className="w3-center w3-padding-small">Product Backlog</h4>
        <ResponsiveContainer className="w3-border-top" width="100%" aspect={1}>
            <PieChart
                margin={{ top: 20, right: 20, left: 20, bottom: 20 }}  >
                <Pie isAnimationActive={false}
                    data={projectStatus.filter(item => item.value > 0)} cx={200}
                    dataKey="value"
                    innerRadius="40%"
                    outerRadius="70%"
                    paddingAngle={2}
                    cy="50%"
                    cx="50%"
                    fill="#8884d8" label>
                    {
                        projectStatus.map((entry, index) => {
                            if (ASSIGNMENTS[entry.name]) {
                                return <Cell key={entry.name} fill={ASSIGNMENTS[entry.name]} />
                            }
                            return <Cell key={entry.name} fill={COLORS[index % COLORS.length]} />
                        })
                    }
                </Pie>
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
        <table className="w3-table w3-striped w3-border ">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Project</th>
                    <th>Issues</th>
                </tr>
            </thead>
            <tbody>
                {
                    projectStatus.sort((a, b) => b.value - a.value).map((row, idx) => {
                        total += parseInt(`${row.value}`);
                        return <tr key={`row_${idx}`}>
                            <td>{idx + 1}</td>
                            <td><Link className="w3-block" to={`/poker/${row.name}`}>{row.name}</Link></td>
                            <td><a href={row.url}>{row.value}</a></td>
                        </tr>}
                    )
                }
                <tr>
                    <td></td>
                    <td><b>Total</b></td>
                    <td>{total}</td>
                </tr>
            </tbody>
        </table>
    </div>
}

export default ProductBacklog;