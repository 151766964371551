import React from 'react';
import { Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { useTicketStats, useMilestone, useTicketGrouper } from '../../../lib/hooks';
import BurnDown from '../../perf/BurnDown';
import { SprintBacklog, TodaysProgress } from '../../widgets/SprintBacklog';
import Leads from './Leads';
import ProductBacklog from './ProductBacklog';
import MemberStats from '../../widgets/MemberStats';
import {BlogStatus} from '../../widgets/BlogStatus';
import { Spinner } from '../../common/Helper';
import { useSelector } from 'react-redux';

const Dashboard = () => {

    const { ticketListFiltered: ticketList, groupedList } = useTicketGrouper();
    const milestone = useMilestone();
    const {
        sprintStatus,
        completionStatus,
        tmTotal,
        prog,
        progVal
    } = useTicketStats(ticketList);
    const appConfig = useSelector(st => st.appConfig) 

    const colorMap = {}; 
    if (!ticketList || !groupedList || !milestone) {
        return <Spinner />
    }

    
    const enabled = appConfig.sections.filter(item => item.module === 'dashboard' && item.enabled === 'TRUE') 
    const enableBlog = enabled.some(item => item.value === 'blog-status');
    const enableBurnDown = enabled.some(item => item.value === 'sprint-burndown');
    const enableProductBacklog = enabled.some(item => item.value === 'product-backlog');
    const enableSprintBacklog = enabled.some(item => item.value === 'sprint-backlog');

    return <div className="w3-row  w3-margin-top">
            <div className="w3-col w3-padding-small l4 m5 s12 w3-margin-bottom">
                <Leads statsList={groupedList} milestone={milestone.title} />
                {enableSprintBacklog && <SprintBacklog sprintStatus={sprintStatus}
                    prog={prog}
                    milestone={milestone?.title}
                    ASSIGNMENTS={colorMap}
                    completionStatus={completionStatus} />}

            </div>
            <div className="w3-col w3-padding-small l5 m7 s12">
                <div className="w3-margin-bottom">
                    <MemberStats
                        statsList={groupedList}
                        milestone={milestone}
                        tmTotal={tmTotal}
                        prog={prog}
                        progVal={progVal} />
                </div>
                <div className="w3-margin-bottom" >
                    {enableBurnDown && <BurnDown issueList={ticketList} />}
                </div>
                {enableProductBacklog && <div className="w3-margin-bottom" >
                    <ProductBacklog
                        ASSIGNMENTS={colorMap} />
                </div>}
            </div>
            <div className="w3-col w3-padding-small l3 m12  s12">
                <div className="w3-col l12 m6 w3-margin-bottom">
                    <TodaysProgress />
                </div>
                <div className="w3-col l12 m6 w3-margin-bottom">
                    {enableBlog && <BlogStatus userList={groupedList} />}
                </div>

            </div>
        </div> 
}

export default Dashboard
