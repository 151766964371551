import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { actionClearStorage, fetchMilestones, fetchUserInfo } from "../../lib/actions";
import { useEffect, useState } from "react";
import { AlertModal, LocationErrorModal, ModalManager, SidebarLink, Spinner } from "./Helper";
import Header from "./Header";
import { IssueModal } from "../perf/IssueBlock";

const Layout = () => {
    const [sp] = useSearchParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { userInfo, appConfig } = useSelector(st => ({
        userInfo: st.userInfo,
        appConfig: st.appConfig
    }));

    useEffect(() => {
        if (userInfo) {
            return;
        }

    
        dispatch(fetchUserInfo(false)).then(resp => {
            const data = resp.data;
            dispatch({
                type: 'SET_APP_CONF',
                data: data.config
            })
            if (document.location.pathname === '/' ||
                document.location.pathname === '/login') {
                navigate('/dashboard')
            }
        }).catch(ex => {
            console.error(ex);
            document.location.href = "/login";
        });
    }, [1])

    useEffect(() => {
        if (!userInfo) {
            return;
        }
        dispatch(fetchMilestones())
    }, [userInfo?.email, dispatch])

    const logout = () => {
        if (!window.confirm('Are you sure you want to logout')) {
            return;
        }
        actionClearStorage();
        document.location.href = "/";
    }
    const query = new URLSearchParams(sp.toString());
    const allowed = ['start', 'end']
    for (const [key] of query.entries()) {
        if (!allowed.some(item => item === key)) {
            query.delete(key)
        }
    }
    const qs = query.toString();

    if (!userInfo) {
        return <Spinner />
    }

    return <div className="w3-light-grey " >
        <nav className="w3-sidebar w3-collapse w3-white  w3-top" style={{ zIndex: 3, width: "240px" }} ><br />
            <div className=" w3-center">
                <img src='/logo128.png' alt="zcalo logo" style={{ height: "64px" }} />
                <h2 className="w3-text-indigo">ZCALO</h2>
                <a href="/" className="w3-hide-large w3-right w3-jumbo w3-padding w3-hover-grey" title="close menu">
                    <i className="ion-navicon-round" style={{ color: 'black' }}></i>
                </a>
            </div>
            <div className="w3-bar-block">
                {userInfo.role === 'Admin' && <>
                    <SidebarLink to={`/admin-dashboard/?${qs}`} >Admin Dashboard</SidebarLink>
                </>}
                {appConfig.sections.filter(item => item.enabled === 'TRUE' && item.module === 'routes').map(item => {
                    return <SidebarLink key={item.value} to={`/${item.value.toLowerCase()}?${qs}`} > {item.name} </SidebarLink>
                })}
                {userInfo.role === 'Admin' && <>
                    <SidebarLink to={`/settings?${qs}`} >Settings</SidebarLink>
                </>}
                <SidebarLink to={`/docs?${qs}`} > Download and FAQ </SidebarLink>
                <button onClick={logout} className='w3-button w3-block w3-text-gray w3-margin-top w3-left-align'> Logout </button>
                <footer style={{ position: 'absolute', bottom: '0px', width: '100%' }}>
                    <div className="w3-theme-l3 w3-center w3-padding "  >
                        <a href="https://zcalo.ai" rel="noreferrer" title="zcalo.ai"
                            target="_blank" className="w3-hover-opacity" style={{ textDecoration: 'none' }}>ZCALO.AI</a></div>
                </footer>
            </div>

        </nav>

        <div className="w3-overlay w3-hide-large w3-animate-opacity"
            style={{ cursor: "pointer" }} title="close side menu" id="myOverlay"></div>

        <div className="w3-main" style={{ marginLeft: "240px" }}>
            <Header />
            <div style={{ minHeight: 'calc(100vh - 60px)', margin: '80px 16px 16px 16px' }}>
                <Outlet />
            </div>
            <AlertModal />
            <LocationErrorModal />
            <IssueModal />
        </div>
    </div>
}

export default Layout;