import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { iAxios } from "../../lib/utils";
import { Footer, LurkerHeader, TabFilter } from "../common/Helper";

const { useSearchParams, Link } = require("react-router-dom")

const DocMarkdown = (props) => {
    const [sp] = useSearchParams();
    const [content, setContent] = useState('');
    const {hasHeaderFooter} =props;

    const mdRef = useRef();
    useEffect(() => {
        let path = sp.get('path');
        if (!path) {
            path = 'faq'
        }
        setContent('Loading...')
        iAxios.get('/api/v1/auth/doc', {
            params: {
                path:`${path}.md`
            }
        }).then(resp => { 
            setContent(resp.data.data)
        })
    }, [sp.get('path')])

    const handleTitleClick = (el) => {
        console.log(el);
    }

    useEffect(()=>{
        // if(!mdRef){
        //     return;
        // }
        // console.log('ref changed'); 
  
        // for(const item of mdRef.current.children) {
        //     if (['H1','H3'].includes(item.tagName)){
        //         item.addEventListener('click', handleTitleClick) ;
        //         continue;
        //     } 
        //     // item.class += 'w3-hide';
        // }

    },[mdRef]);

    return <div className="">
        {hasHeaderFooter && <LurkerHeader/>}
        
        <div className="w3-margin-top" style={{minHeight:'calc( 100vh - 120px)'}}>
            {!hasHeaderFooter && <TabFilter name="path" filters={[
                "FAQ",
                "Download",
                "Privacy",
                "Terms", 
            ]} /> }

            <div className={` w3-white w3-padding w3-margin-top zcalo-doc  w3-large page-${sp.get('path') || 'faq'}`} ref={mdRef}>
                <ReactMarkdown >{content}</ReactMarkdown>
            </div>
        </div>
        {hasHeaderFooter && <Footer/>}
    </div>

}

export default DocMarkdown;