import { useEffect, useState } from "react"
import { iAxios } from "../../../lib/utils"
import { useParams } from "react-router" 
import { Users } from "./Users"
import { useDispatch } from "react-redux"
import { alertMessage } from "../../../lib/actions"
import { Integrations } from "./Integrations"
import { TabFilter } from "../../common/Helper"



const Preferences = (props) => {
}
const Settings = (props) => {
    const { tab = 'users' } = useParams();
    return <>
        <TabFilter mode="path" basePath="/settings"
            filters={['Users', 'Integrations',]} ></TabFilter>
        {tab === 'users' && <Users />}
        {tab === 'integrations' && <Integrations />}
    </>
}

export default Settings;