import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHref, useParams } from 'react-router-dom';
import * as classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { STATUS_COLOR_MAP } from '../../lib/utils';

export const Spinner = (props = { message: 'Loading Data', mode: 'big' }) => {
    let paddingCls = 'w3-padding-64';
    if (props.mode == 'small') {
        paddingCls = 'w3-padding-small cgs-loader-small';
    }
    return <div className={`w3-center ${paddingCls} w3-block`} >
        <div className="w3-center w3-padding">
            <div className="cgs-loader"></div>
        </div>
        {props.message}
    </div>
}


export const TRWrapper = (props) => {
    return <tr className={props.className}><td colSpan={props.colSpan}>{props.children}</td></tr>
}

export const ButtonGroup = ({ buttons, onSelect, selected }) => {
    return <div className="w3-bar w3-center">
        {buttons.map(b => {
            let cls = '';
            if (b == selected) {
                cls = 'w3-blue';
            }
            return <button key={b} onClick={onSelect.bind(this, b)} className={`w3-button w3-small w3-padding-small ${cls}`}>{b}</button>
        })}
    </div>
}


export const SidebarLink = (props) => {
    return <NavLink className="w3-bar-item w3-button w3-padding w3-big " {...props} style={({ isActive }) => ({
        color: isActive ? '#302fde' : '#888',
        fontWeight: isActive ? 'bold' : 'normal',
        background: isActive ? '#EEE' : '#FFF'
    })}
    >{props.children}</NavLink>
}

export const CopLogin = () => {
    const [url, setUrl] = useState('cgs-copilot://')
    useEffect(() => {
        const tok = localStorage.getItem('access_token');
        const u = `cgs-copilot://login/${tok}`;
        setUrl(u);
        setTimeout(() => {
            window.location.href = u;
        }, 1000)
    }, [1])
    return <div className='w3-padding-64'>
        <a href={url}>Click Here to login to CGS Copilot</a>

    </div>
}
export const NotFound = () => {
    return <div className='w3-center w3-padding-64'>
        <h1>404 - Page Not Found

            <Link to="/">Go home</Link>
        </h1>
    </div>;
}

export const LurkerHeader = () => {
    return <div className='w3-theme-d1 w3-padding-small '>
        <Link className='' to={'/'} style={{ textDecoration: 'none' }}>
            <h4 style={{ margin: '0px', lineHeight: '48px', fontSize: '42px' }}> <img src="/logo.png" style={{ height: '38px', marginBottom: '6px' }} alt="logo" /> ZCALO</h4>
        </Link>
    </div>
}

export const Footer = (props) => {

    return <footer className=' w3-theme-l3 w3-center '
        style={{ width: '100%' }}>
        <div className='w3-bar'>
            <Link className='w3-bar-item ' to={`/pages?path=faq`}>FAQ</Link>
            <Link className='w3-bar-item ' to={`/pages?path=download`}>Download</Link>
            <Link className='w3-bar-item ' to={`/pages?path=terms`}>Terms Of Service</Link>
            <Link className='w3-bar-item ' to={`/pages?path=privacy`}>Privacy</Link>
            <Link className='w3-bar-item ' to={`/login`}>Home</Link>
        </div>
    </footer>
}
export const TabFilter = (props) => {

    const { name, count, basePath = document.location.pathname, filters = [], mode = 'query' } = props;
    const qs = new URLSearchParams(document.location.search);
    const { tab } = useParams()

    let current = qs.get(name);
    if (mode === 'path') {
        current = tab;
    }
    if (!current && filters.length > 0) {
        current = filters[0].toLowerCase()
    }


    const tabs = filters?.map(item => {
        let cls = '';
        let strName = item;
        let label = null;
        if (current === item.toLowerCase()) {
            cls = 'w3-light-grey w3-text-indigo w3-bold';
            if (count > 0) {
                label = <span className=" w3-round w3-red">{count}</span>
            }
        }

        const parts = basePath.split('/');
        if (mode === 'path') {
            parts.push(item.toLowerCase());
        } else {
            qs.set(name, item.toLowerCase())
        }
        return <Link key={strName} className={`w3-bar-item cgs-tab-bar w3-button ${cls} `}
            to={`${parts.join('/')}?${qs.toString()}`}>
            {strName} {label}
        </Link>
    })

    return <div className="w3-bar cgs w3-theme-l3 ">
        <div className="w3-left">
            <div className="w3-padding-small">
                {tabs}
            </div>
        </div>
    </div>
}


const useFormData = (fields, initVals) => {
    const [formData, setFormData] = useState({});
    const setValue = (name, value) => {
        setFormData(Object.assign({}, formData, {
            [name]: value
        }))
    }
    useEffect(() => {
        const data = fields.map((acc, item) => {
            if (initVals[item.name]) {
                if (item.type === 'date') {
                    acc[item.name] = moment(initVals[item.name]).format('YYYY-MM-DD');
                } else {

                    acc[item.name] = initVals[item.name];
                }

            }
            return acc;
        })

        setFormData(data)
    }, fields);

    return {
        setValue,
        formData
    }
}

export const ModalManager = (props) => {
    const { modalName, children, className } = props;
    const modalData = useSelector(st => st.modalStatus[modalName]);
    const dispatch = useDispatch();

    const hideModal = () => {
        dispatch({
            type: 'hide-modal',
            name: modalName
        })
    }

    const params = { 'w3-modal': true, 'w3-show': modalData, [modalName]: true };
    return <div className={classNames(params)}>
        <div className={classNames("w3-modal-content w3-round", className)} >
            <div className="w3-container w3-padding-32" >
                <span onClick={hideModal}
                    className="w3-button w3-display-topright">&times;</span>
                {children}
            </div>
        </div>
    </div>
}

export const AlertModal = () => {
    const data = useSelector(st => st.modalStatus['alertModal'])
    return <ModalManager modalName="alertModal">
        {data && <div  >
            <h3>{data.title}</h3>
            <div className={data.msgCls}>{data.message}</div>
        </div>}
    </ModalManager>
}

export const StatusLabel = ({ info }) => {

    const statusColor = STATUS_COLOR_MAP[info.status];
    return <span className={`w3-small progress-label  ${statusColor}`}
        title={moment.unix(info.statusTime / 1000).fromNow()}> {info.status}</span>
}

export const LocationErrorModal = () => {

    const message = useSelector(st => st.modalStatus['locationError'])
    return <ModalManager modalName="locationError">
        <div className='w3-center'>
            <h3>{message}</h3>
            <div className='w3-row-padding'>
                <div className='w3-col m4'>
                    <img className="w3-image w3-image" alt="permission off"
                        src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/zcalo/img/loc-on.jpg?v=1" />


                </div>
                <div className='w3-col m4'>
                    <img className="w3-image w3-image" alt="permission start"
                        src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/zcalo/img/loc-off.jpg?v=1" />

                </div>
                <div className='w3-col m4'>
                    <img className="w3-image w3-image" alt="reload and give premission"
                        src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/zcalo/img/loc-accept.jpg?v=1" />
                </div>

            </div>

        </div>
    </ModalManager>
}